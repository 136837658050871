/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, WebError } from '@dpa/ui-common';
import { createAction, props } from '@ngrx/store';

import {
  ConnectionDeauthorizeRequest,
  ConnectionSetupRequest,
  EventForwarderTemplate,
  ServiceConfigTemplate,
} from '@dpa-shared-merlot/model';
import {
  AvailableEventForwarder,
  AvailablePartner,
  AvailableService,
  AvailableServiceSearchResponse,
  AvailableServiceTenant,
  EventForwarderConnectionModalSection,
  TrustNetworkPartnerConnectionModalSection,
} from '@ws1c/intelligence-models';

/**
 * ConnectionActions
 * @export
 * @class ConnectionActions
 */
export class ConnectionActions {
  public static readonly CATEGORY = '[CONNECTION]';

  public static loadServiceConfigTemplates = createAction(`${ConnectionActions.CATEGORY} LOAD_SERVICE_CONFIG_TEMPLATES`);

  public static loadServiceConfigTemplatesSuccess = createAction(
    `${ConnectionActions.CATEGORY} LOAD_SERVICE_CONFIG_TEMPLATES_SUCCESS`,
    props<{ templates: ServiceConfigTemplate[] }>(),
  );

  public static loadServiceConfigTemplatesFailure = createAction(
    `${ConnectionActions.CATEGORY} LOAD_SERVICE_CONFIG_TEMPLATES_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static loadTrustNetworkServices = createAction(`${ConnectionActions.CATEGORY} LOAD_TRUST_NETWORK_SERVICES`);

  public static loadTrustNetworkServicesSuccess = createAction(
    `${ConnectionActions.CATEGORY} LOAD_TRUST_NETWORK_SERVICES_SUCCESS`,
    props<{ response: AvailableServiceSearchResponse }>(),
  );

  public static loadTrustNetworkServicesFailure = createAction(
    `${ConnectionActions.CATEGORY} LOAD_TRUST_NETWORK_SERVICES_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static goToAutomationDetailPage = createAction(
    `${ConnectionActions.CATEGORY} GO_TO_AUTOMATION_DETAIL_PAGE`,
    props<{ automationId: string }>(),
  );

  public static getConnectionDetails = createAction(`${ConnectionActions.CATEGORY} GET_CONNECTION_DETAILS`, props<{ id: string }>());

  public static getConnectionDetailsSuccess = createAction(
    `${ConnectionActions.CATEGORY} GET_CONNECTION_DETAILS_SUCCESS`,
    props<{ availableService: AvailableService }>(),
  );

  public static getConnectionDetailsFailure = createAction(
    `${ConnectionActions.CATEGORY} GET_CONNECTION_DETAILS_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static requestSetupConnection = createAction(
    `${ConnectionActions.CATEGORY} REQUEST_SETUP_CONNECTION`,
    props<{ availableService: AvailableService }>(),
  );

  public static confirmSetupConnection = createAction(`${ConnectionActions.CATEGORY} CONFIRM_SETUP_CONNECTION`);

  public static processSetupConnection = createAction(`${ConnectionActions.CATEGORY} PROCESS_SETUP_CONNECTION`);

  public static confirmEditConnection = createAction(`${ConnectionActions.CATEGORY} CONFIRM_EDIT_CONNECTION`);

  public static cancelConfirmEditConnection = createAction(`${ConnectionActions.CATEGORY} CANCEL_CONFIRM_EDIT_CONNECTION`);

  public static requestEditConnectionSuccess = createAction(
    `${ConnectionActions.CATEGORY} REQUEST_EDIT_CONNECTION_SUCCESS`,
    props<{ settings: GenericObject }>(),
  );

  public static requestEditConnectionFailure = createAction(
    `${ConnectionActions.CATEGORY} REQUEST_EDIT_CONNECTION_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static updateConnectionSetupRequest = createAction(
    `${ConnectionActions.CATEGORY} UPDATE_CONNECTION_SETUP_REQUEST`,
    props<{ request: Partial<ConnectionSetupRequest> }>(),
  );

  public static closeConnectionModal = createAction(`${ConnectionActions.CATEGORY} CLOSE_CONNECTION_MODAL`);

  public static loadPartners = createAction(`${ConnectionActions.CATEGORY} LOAD_PARTNERS`);

  public static loadPartnersSuccess = createAction(
    `${ConnectionActions.CATEGORY} LOAD_PARTNERS_SUCCESS`,
    props<{ partners: AvailablePartner[] }>(),
  );

  public static setupTrustNetworkPartnerConnection = createAction(
    `${ConnectionActions.CATEGORY} SETUP_TRUST_NETWORK_PARTNER_CONNECTION`,
    props<{ email: string; clientId?: string; clientSecret?: string }>(),
  );

  public static updateTrustNetworkPartnerConnection = createAction(
    `${ConnectionActions.CATEGORY} UPDATE_TRUST_NETWORK_PARTNER_CONNECTION`,
    props<{ email: string; clientId?: string; clientSecret?: string }>(),
  );

  public static setupOrUpdateTrustNetworkPartnerConnectionSuccess = createAction(
    `${ConnectionActions.CATEGORY} SETUP_OR_UPDATE_TRUST_NETWORK_PARTNER_CONNECTION_SUCCESS`,
    props<{ availablePartner: AvailablePartner }>(),
  );

  public static setupOrUpdateTrustNetworkPartnerConnectionFailure = createAction(
    `${ConnectionActions.CATEGORY} SETUP_OR_UPDATE_TRUST_NETWORK_PARTNER_CONNECTION_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static regenerateTrustNetworkPartnerConnectionToken = createAction(
    `${ConnectionActions.CATEGORY} REGENERATE_TRUST_NETWORK_PARTNER_CONNECTION_TOKEN`,
  );

  public static regenerateTrustNetworkPartnerConnectionTokenSuccess = createAction(
    `${ConnectionActions.CATEGORY} REGENERATE_TRUST_NETWORK_PARTNER_CONNECTION_TOKEN_SUCCESS`,
    props<{ availablePartner: AvailablePartner }>(),
  );

  public static regenerateTrustNetworkPartnerConnectionTokenFailure = createAction(
    `${ConnectionActions.CATEGORY} REGENERATE_TRUST_NETWORK_PARTNER_CONNECTION_TOKEN_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static removeTrustNetworkPartnerConnectionToken = createAction(
    `${ConnectionActions.CATEGORY} REMOVE_TRUST_NETWORK_PARTNER_CONNECTION_TOKEN`,
  );

  public static deauthorizeTrustNetworkPartnerConnection = createAction(
    `${ConnectionActions.CATEGORY} DEAUTHORIZE_TRUST_NETWORK_PARTNER_CONNECTION`,
  );

  public static deauthorizeTrustNetworkPartnerConnectionSuccess = createAction(
    `${ConnectionActions.CATEGORY} DEAUTHORIZE_TRUST_NETWORK_PARTNER_CONNECTION_SUCCESS`,
  );

  public static deauthorizeTrustNetworkPartnerConnectionFailure = createAction(
    `${ConnectionActions.CATEGORY} DEAUTHORIZE_TRUST_NETWORK_PARTNER_CONNECTION_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static openSetupOrEditPartnerConnectionModal = createAction(
    `${ConnectionActions.CATEGORY} OPEN_SETUP_OR_EDIT_PARTNER_CONNECTION_MODAL`,
    props<{ availablePartner: AvailablePartner }>(),
  );

  public static openDeauthorizePartnerConnectionModal = createAction(
    `${ConnectionActions.CATEGORY} OPEN_DEAUTHORIZE_PARTNER_CONNECTION_MODAL`,
    props<{ availablePartner: AvailablePartner }>(),
  );

  public static closePartnerConnectionModal = createAction(`${ConnectionActions.CATEGORY} CLOSE_PARTNER_CONNECTION_MODAL`);

  public static togglePartnerConnectionModalSection = createAction(
    `${ConnectionActions.CATEGORY} TOGGLE_PARTNER_CONNECTION_MODAL_SECTION`,
    props<{ trustNetworkPartnerConnectionModalSection: TrustNetworkPartnerConnectionModalSection }>(),
  );

  public static requestDeauthorizeConnection = createAction(
    `${ConnectionActions.CATEGORY} REQUEST_DEAUTHORIZE_CONNECTION`,
    props<{
      availableService: AvailableService;
      availableServiceTenant?: AvailableServiceTenant;
    }>(),
  );

  public static confirmDeauthorizeConnection = createAction(`${ConnectionActions.CATEGORY} CONFIRM_DEAUTHORIZE_CONNECTION`);

  public static updateConnectionDeauthorizeRequest = createAction(
    `${ConnectionActions.CATEGORY} UPDATE_CONNECTION_DEAUTHORIZE_REQUEST`,
    props<{ connectionDeauthorizeRequest: Partial<ConnectionDeauthorizeRequest> }>(),
  );

  public static openPreviewConnectionSlideOver = createAction(
    `${ConnectionActions.CATEGORY} OPEN_PREVIEW_CONNECTION_SLIDE_OVER`,
    props<{ availableService: AvailableService }>(),
  );

  public static closePreviewConnectionSlideOver = createAction(`${ConnectionActions.CATEGORY} CLOSE_PREVIEW_CONNECTION_SLIDE_OVER`);

  public static loadEventForwardersTemplates = createAction(`${ConnectionActions.CATEGORY} LOAD_EVENT_FORWARDERS_TEMPLATES`);

  public static loadEventForwardersTemplatesSuccess = createAction(
    `${ConnectionActions.CATEGORY} LOAD_EVENT_FORWARDERS_TEMPLATES_SUCCESS`,
    props<{ templates: EventForwarderTemplate[] }>(),
  );

  public static loadTrustNetworkEventForwarders = createAction(`${ConnectionActions.CATEGORY} LOAD_TRUST_NETWORK_EVENT_FORWARDERS`);

  public static loadTrustNetworkEventForwardersSuccess = createAction(
    `${ConnectionActions.CATEGORY} LOAD_TRUST_NETWORK_EVENT_FORWARDERS_SUCCESS`,
    props<{ trustNetworkEventForwarders: AvailableEventForwarder[] }>(),
  );

  public static openSetupOrEditEventForwarderConnectionModal = createAction(
    `${ConnectionActions.CATEGORY} OPEN_SETUP_OR_EDIT_EVENT_FORWARDER_CONNECTION_MODAL`,
    props<{ availableEventForwarder: AvailableEventForwarder }>(),
  );

  public static toggleEventForwarderConnectionModalSection = createAction(
    `${ConnectionActions.CATEGORY} TOGGLE_EVENT_FORWARDER_CONNECTION_MODAL_SECTION`,
    props<{ eventForwarderConnectionModalSection: EventForwarderConnectionModalSection }>(),
  );

  public static setupOrUpdateEventForwarderConnection = createAction(
    `${ConnectionActions.CATEGORY} SETUP_OR_UPDATE_EVENT_FORWARDER_CONNECTION`,
    props<{ metaFormFields: GenericObject }>(),
  );

  public static setupOrUpdateEventForwarderConnectionSuccess = createAction(
    `${ConnectionActions.CATEGORY} SETUP_OR_UPDATE_EVENT_FORWARDER_CONNECTION_SUCCESS`,
    props<{ availableEventForwarder: AvailableEventForwarder }>(),
  );

  public static setupOrUpdateEventForwarderConnectionFailure = createAction(
    `${ConnectionActions.CATEGORY} SETUP_OR_UPDATE_EVENT_FORWARDER_CONNECTION_FAILURE`,
    props<{ error: WebError }>(),
  );

  public static confirmUpdateEventForwarderConnection = createAction(
    `${ConnectionActions.CATEGORY} CONFIRM_UPDATE_EVENT_FORWARDER_CONNECTION`,
  );

  public static closeConfirmUpdateEventForwarderConnectionModal = createAction(
    `${ConnectionActions.CATEGORY} CLOSE_CONFIRM_UPDATE_EVENT_FORWARDER_CONNECTION_MODAL`,
  );

  public static closeEventForwarderConnectionModal = createAction(`${ConnectionActions.CATEGORY} CLOSE_EVENT_FORWARDER_CONNECTION_MODAL`);

  public static openDeauthorizeEventForwarderConnectionModal = createAction(
    `${ConnectionActions.CATEGORY} OPEN_DEAUTHORIZE_EVENT_FORWARDER_CONNECTION_MODAL`,
    props<{ availableEventForwarder: AvailableEventForwarder }>(),
  );

  public static deauthorizeEventForwarderConnection = createAction(`${ConnectionActions.CATEGORY} DEAUTHORIZE_EVENT_FORWARDER_CONNECTION`);

  public static deauthorizeEventForwarderConnectionSuccess = createAction(
    `${ConnectionActions.CATEGORY} DEAUTHORIZE_EVENT_FORWARDER_CONNECTION_SUCCESS`,
    props<{ availableEventForwarder: AvailableEventForwarder }>(),
  );

  public static deauthorizeEventForwarderConnectionFailure = createAction(
    `${ConnectionActions.CATEGORY} DEAUTHORIZE_EVENT_FORWARDER_CONNECTION_FAILURE`,
  );

  public static revokePartner = createAction(
    `${ConnectionActions.CATEGORY} REVOKE_PARTNER`,
    props<{
      availablePartner: AvailablePartner;
    }>(),
  );

  public static revokePartnerSuccess = createAction(`${ConnectionActions.CATEGORY} REVOKE_PARTNER_SUCCESS`);

  public static revokePartnerFailure = createAction(`${ConnectionActions.CATEGORY} REVOKE_PARTNER_FAILURE`, props<{ error: WebError }>());

  public static deauthorizeFromIntegrationViewSuccess = createAction(
    `${ConnectionActions.CATEGORY} DEAUTHORIZE_FROM_INTEGRATION_VIEW_SUCCESS`,
  );
}
