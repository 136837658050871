/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FullPageElementService } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { combineLatest, filter, Observable, Subscription } from 'rxjs';

import { getRouterURLWithoutParameters, isExternalLink } from '@ws1c/intelligence-common';
import { AlertBanner, AlertBannerActions, AlertBannerSelectors, AlertItem } from '@ws1c/intelligence-core/store/alert-banner';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import { AlertBannerTarget } from '@ws1c/intelligence-models';

/**
 * Component for the alert banner
 * @export
 * @class AlertBannerComponent
 */
@Component({
  selector: 'dpa-alert-banner',
  templateUrl: 'alert-banner.component.html',
  styleUrls: ['alert-banner.component.scss'],
})
export class AlertBannerComponent implements OnInit, OnDestroy {
  @Input() public target: AlertBannerTarget;
  @Output() public actionClick: EventEmitter<void> = new EventEmitter<void>();

  public appLevel: boolean;
  public pageLevel: boolean;
  public alertBanner$: Observable<AlertBanner>;
  public alertItems: AlertItem[] = [];
  public sub: Subscription = new Subscription();
  public isVisible: boolean = false;
  public isExternalLink = isExternalLink;
  public previousRoute: string;
  private dismissTimer = null;

  /**
   * Initializes AlertBannerComponent instance
   * @param {Store<CoreAppState>} store - Store instance
   * @param {FullPageElementService} fullPageElementService
   * @param {Router} router
   * @memberof AlertBannerComponent
   */
  constructor(
    private store: Store<CoreAppState>,
    private fullPageElementService: FullPageElementService,
    private router: Router,
  ) {}

  /**
   * ngOnInit
   * @memberof AlertBannerComponent
   */
  public ngOnInit() {
    this.alertBanner$ = this.store.select(AlertBannerSelectors.getAlertBannerStateByTarget(this.target));
    this.sub.add(this.alertBanner$.subscribe((banner: AlertBanner) => this.onAlertBannerChange(banner)));
    this.appLevel = this.target === AlertBannerTarget.APP;
    this.pageLevel = this.target === AlertBannerTarget.PAGE;
    if (this.pageLevel) {
      this.sub.add(
        combineLatest([this.alertBanner$, this.router.events])
          .pipe(
            filter(([alertBanner, event]: [AlertBanner, NavigationEnd]) => {
              return event instanceof NavigationEnd && alertBanner?.visible;
            }),
          )
          .subscribe(([_alertBanner, event]: [AlertBanner, NavigationEnd]) => {
            const currentRoute = getRouterURLWithoutParameters(event.url);
            if (this.previousRoute && currentRoute !== this.previousRoute) {
              this.onClose();
              return;
            }
            this.previousRoute = currentRoute;
          }),
      );
    }
  }

  /**
   * ngOnDestroy
   * @memberof AlertBannerComponent
   */
  public ngOnDestroy() {
    this.onClose();
    this.sub.unsubscribe();
  }

  /**
   * onAlertBannerChange
   * @param {AlertBanner} banner
   * @memberof AlertBannerComponent
   */
  public onAlertBannerChange(banner: AlertBanner) {
    this.isVisible = banner?.visible ?? false;
    this.alertItems = [];
    if (!banner) {
      return;
    }
    if (banner.visible) {
      this.triggerResize();
    }
    this.alertItems = banner.alertItems ?? [banner];
    clearTimeout(this.dismissTimer);
    if (banner.visible && banner.autoDismiss) {
      this.dismissTimer = setTimeout(() => this.onClose(), banner.hideAfter);
    }
  }

  /**
   * onActionClick
   * @param {Event} $event - click event object
   * @param {string} actionLink - action link url or route
   * @memberof AlertBannerComponent
   */
  public onActionClick($event: Event, actionLink: string) {
    if (!actionLink) {
      $event.stopPropagation();
      $event.preventDefault();
    }
    this.actionClick.emit();
  }

  /**
   * onClose
   * @memberof AlertBannerComponent
   */
  public onClose() {
    this.triggerResize();
    this.store.dispatch(AlertBannerActions.dismissAlertBanner({ target: this.target }));
    this.previousRoute = undefined;
  }

  /**
   * triggerResize
   * @memberof AlertBannerComponent
   */
  private triggerResize() {
    if (this.appLevel) {
      setTimeout(() => this.fullPageElementService.resizeContainer(), 30);
    }
  }
}
