/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GenericObject, ModalSize, PagedRequest, unsubscribe } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';

import { AlertBannerActions } from '@ws1c/intelligence-core/store/alert-banner';
import {
  AutomationCommonActions,
  automationCommonHelpers,
  AutomationCommonSelectors,
} from '@ws1c/intelligence-core/store/automation-common';
import { ConnectionCommonActions, ConnectionCommonSelectors } from '@ws1c/intelligence-core/store/connection-common';
import { ConnectorCommonActions } from '@ws1c/intelligence-core/store/connector-common';
import { CoreAppState } from '@ws1c/intelligence-core/store/core-app-state';
import {
  ActionType,
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  AutomationAction,
  AutomationConnectionTest,
  AutomationTestConnectionResult,
  Column,
  ColumnIndex,
  ConnectionModalSection,
  ConnectionTestType,
  Connector,
  ConnectorAction,
  ConnectorActionSection,
  ConnectorConfigStatus,
  ConnectorModalType,
  CustomReportPreviewSearchResponse,
  DataGridColumn,
  FilterRule,
  getMergedFormForSectionType,
  helpers,
  MetaForm,
  MetaFormField,
  MetaFormFieldPresentationType,
  ServiceAction,
  TestAction,
} from '@ws1c/intelligence-models';

/**
 * Display the enable and disable switch for automation.
 *
 * @export
 * @class AutomationTestActionComponent
 * @implements {OnChanges}
 * @implements {OnDestroy}
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-automation-test-action',
  templateUrl: 'automation-test-action.component.html',
  styleUrls: ['automation-test-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomationTestActionComponent implements OnChanges, OnDestroy, OnInit {
  public readonly DEFAULT_PAGE_SIZE = 5;
  public readonly AVAILABLE_PAGE_SIZES = [this.DEFAULT_PAGE_SIZE, 10];
  public readonly CONNECTOR_CONFIG_STATUS = ConnectorConfigStatus;
  public readonly ModalSize = ModalSize;
  public readonly MetaFormFieldPresentationType = MetaFormFieldPresentationType;
  public readonly ConnectionTestType = ConnectionTestType;
  public readonly ALERT_BANNER_TYPE = ALERT_BANNER_TYPE;

  @Input() public testType?: ConnectionTestType = ConnectionTestType.AUTOMATION;
  @Input() public isModalOpen: boolean;
  @Input() public isTestRunning: boolean;
  @Input() public testHeader: AutomationAction;
  @Input() public testData: MetaFormField[] | MetaForm;
  @Input() public isTestTemplateInvalid: boolean;
  @Output() public onClose = new EventEmitter();
  @Output() public onFormChange = new EventEmitter();

  public testAction$: Observable<ConnectionModalSection>;
  public testResult$: Observable<AutomationTestConnectionResult>;
  public dynamicValuesData$: Observable<CustomReportPreviewSearchResponse>;
  public dynamicValuesDataGridColumns$: Observable<DataGridColumn[]>;
  public isDynamicValuesDataLoading$: Observable<boolean>;
  public dynamicValuesColumnsByName: ColumnIndex;
  public dynamicValuesColumns$: Observable<Column[]>;
  public unknownTestAttributes: Set<string>;
  public filterRule: FilterRule = new FilterRule();
  public metaForm: MetaForm;
  public action: AutomationAction;
  public originalActionWithDynamicValues: AutomationAction;
  public selectedDynamicValueData: GenericObject;
  public alertBannerTarget = AlertBannerTarget;
  public connectionModalSection = ConnectionModalSection;
  public connectionTestType = ConnectionTestType;
  public connector: Connector;
  public subs: Subscription[];
  public isTestButtonDisabled: boolean;
  public formsBySectionType: Record<string, UntypedFormGroup> = {};
  public connectorAction: ConnectorAction;
  public testableFormSections: ConnectorActionSection[];
  public replacedDynamicFormValues: GenericObject;
  public initialDynamicFormValues: GenericObject;

  /**
   * @param {Store<CoreAppState>} store
   * @memberof AutomationTestActionComponent
   */
  constructor(private store: Store<CoreAppState>) {
    this.testAction$ = this.store.select(ConnectionCommonSelectors.getConnectionModalSection);
    this.dynamicValuesDataGridColumns$ = this.store.select(AutomationCommonSelectors.getDynamicValuesDataGridColumns);
    this.dynamicValuesData$ = this.store.select(AutomationCommonSelectors.getTestDynamicValues);
    this.isDynamicValuesDataLoading$ = this.store.select(AutomationCommonSelectors.getTestDynamicValuesLoading);
    this.dynamicValuesColumns$ = this.store.select(AutomationCommonSelectors.getDynamicValuesColumns);
    this.testResult$ = this.store.select(AutomationCommonSelectors.getAutomationTestResult);
  }

  /**
   * ngOninit
   * @memberof AutomationTestActionComponent
   */
  public ngOnInit() {
    if (this.testType === ConnectionTestType.AUTOMATION) {
      this.testResult$ = this.store.select(AutomationCommonSelectors.getAutomationTestResult);
    }
    if (this.testType === ConnectionTestType.CUSTOM) {
      this.testResult$ = this.store.select(ConnectionCommonSelectors.getConnectionTestResult);
    }
    this.subs = [
      this.store.select(AutomationCommonSelectors.getTestableConnectorAction).subscribe((action: ConnectorAction) => {
        this.connectorAction = action;
        this.testableFormSections = helpers.getFlattenConnectorActionSections(action?.sections);
      }),
      this.store.select(AutomationCommonSelectors.getTestableConnector).subscribe((connector: Connector) => {
        this.connector = connector;
      }),
      this.testResult$.subscribe(() => {
        this.toggleTestSection(ConnectionModalSection.RESULT);
      }),
      this.store.select(AutomationCommonSelectors.getTestableMetaForm).subscribe((form: MetaForm) => {
        this.metaForm = form;
      }),
      this.store.select(AutomationCommonSelectors.getTestableAutomationAction).subscribe((action: AutomationAction) => {
        this.originalActionWithDynamicValues = cloneDeep(action);
        this.action = action;
        this.initialDynamicFormValues = helpers.getDynamicFormValuesFromAutomationAction(this.action);
        this.replacedDynamicFormValues = this.initialDynamicFormValues;
      }),
      this.store.select(AutomationCommonSelectors.getDynamicValuesColumnsByName).subscribe((columnsByName: ColumnIndex) => {
        this.dynamicValuesColumnsByName = columnsByName;
      }),
      this.store.select(AutomationCommonSelectors.getUnknownTestAttributes).subscribe((attributes: Set<string>) => {
        this.unknownTestAttributes = attributes;
      }),
    ];
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof AutomationTestActionComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes?.testData?.currentValue && this.testType === ConnectionTestType.AUTOMATION) {
      const attributes: Set<string> = new Set(
        changes.testData.currentValue.reduce((attr: string[], field: MetaFormField) => {
          return [...attr, ...MetaFormField.extractDynamicValues(field)];
        }, []),
      );

      this.store.dispatch(
        AutomationCommonActions.setTestAttributes({
          testAttributes: attributes,
        }),
      );
      this.store.dispatch(
        AutomationCommonActions.loadTestDynamicValuesTable({
          pagedRequest: new PagedRequest({
            from: 0,
            size: 5,
          }),
        }),
      );
    }
    if (changes?.isModalOpen?.currentValue) {
      const section =
        this.testType === ConnectionTestType.AUTOMATION && !this.unknownTestAttributes?.size
          ? ConnectionModalSection.RESOLVE_DYNAMIC_VALUES
          : ConnectionModalSection.TEST;
      this.toggleTestSection(section);
      this.filterRule = new FilterRule();
    }
    this.isTestButtonDisabled = this.checkIfTestButtonDisabled();
  }

  /**
   * ngOnDestroy
   * @memberof AutomationTestActionComponent
   */
  public ngOnDestroy() {
    unsubscribe(this.subs);
  }

  /**
   * closeModal
   * @memberof AutomationTestActionComponent
   */
  public closeModal() {
    this.store.dispatch(ConnectionCommonActions.toggleModalSection({}));
    this.store.dispatch(AlertBannerActions.dismissAlertBanner({ target: AlertBannerTarget.MODAL }));
    this.onClose.emit();
    this.formsBySectionType = {};
  }

  /**
   * sendTest
   * @memberof AutomationTestActionComponent
   */
  public sendTest() {
    const dataBySection = Object.keys(this.formsBySectionType).reduce(
      (accum: Record<string, MetaFormField[]>, sectionType: string) => ({
        ...accum,
        [sectionType]: Object.entries(this.formsBySectionType[sectionType].value as GenericObject).map(
          ([name, value]: string[]) => new MetaFormField({ name, value }),
        ),
      }),
      {},
    );
    const actionTest = new AutomationConnectionTest({
      serviceId: this.connectorAction.connectorId,
      actionTemplateId: this.connectorAction.id,
      serviceAction: new ServiceAction({
        serviceKey: ActionType.OPENAPI,
        serviceActionSubtype: ActionType.OPENAPI,
        action: new TestAction({
          key: this.connectorAction.metadata?.actionType,
          dataBySection,
        }),
      }),
    });
    this.store.dispatch(AutomationCommonActions.testConnectorAction({ actionTest }));
  }

  /**
   * toggleTestSection
   * @param {ConnectionModalSection} connectionModalSection
   * @memberof AutomationTestActionComponent
   */
  public toggleTestSection(connectionModalSection: ConnectionModalSection) {
    this.store.dispatch(ConnectionCommonActions.setTestConnectionModalSection({ connectionModalSection }));
  }

  /**
   * automationActionFormGroupChange
   * @param {UntypedFormGroup} form
   * @memberof AutomationTestActionComponent
   */
  public automationActionFormGroupChange(form: UntypedFormGroup) {
    this.action = AutomationAction.create(this.action, helpers.getMetaFormSettingsFromFormGroup(form));
    this.isTestButtonDisabled = this.checkIfTestButtonDisabled();
  }

  /**
   * automationConnectorActionFormGroupChange
   * @param {string} sectionType
   * @param {UntypedFormGroup} form
   * @memberof AutomationTestActionComponent
   */
  public automationConnectorActionFormGroupChange(sectionType: string, form: UntypedFormGroup) {
    this.formsBySectionType[sectionType] = getMergedFormForSectionType(this.formsBySectionType, sectionType, form);
    this.action = AutomationAction.create(this.action, helpers.getSettingsFromFormsBySectionType(this.formsBySectionType));
    this.isTestButtonDisabled = this.checkIfTestButtonDisabled();
  }

  /**
   * onTestActionFormGroupChange
   * @param {UntypedFormGroup} form
   * @memberof AutomationTestActionComponent
   */
  public onTestActionFormGroupChange(form: UntypedFormGroup) {
    this.onFormChange.emit(form);
    this.isTestButtonDisabled = this.checkIfTestButtonDisabled();
  }

  /**
   * dynamicValuesPageChange
   * @param {PagedRequest} page
   * @memberof AutomationTestActionComponent
   */
  public dynamicValuesPageChange(page?: PagedRequest) {
    this.store.dispatch(
      AutomationCommonActions.loadTestDynamicValuesTable({
        pagedRequest: page,
        filterRule: this.filterRule,
      }),
    );
  }

  /**
   * dynamicValuesSelectionChange
   * @param {GenericObject[]} selected
   * @memberof AutomationTestActionComponent
   */
  public dynamicValuesSelectionChange(selected: GenericObject[]) {
    if (selected && selected[0]) {
      this.selectedDynamicValueData = selected[0];
      this.updateActionWithReplacedDynamicValues();
      this.toggleTestSection(this.connectionModalSection.TEST);
    }
  }

  /**
   * updateActionWithReplacedDynamicValues
   * @memberof AutomationTestActionComponent
   */
  public updateActionWithReplacedDynamicValues() {
    const settings = cloneDeep(this.originalActionWithDynamicValues.actionData?.settings);
    const dynamicFormValues = cloneDeep(this.initialDynamicFormValues);
    Object.keys(this.selectedDynamicValueData).forEach((selectedDynamicValue: string) => {
      const dynamicValue = `\\$\\{${selectedDynamicValue}\\}`;
      if (settings) {
        this.action.actionData.settings = this.replaceDynamicValues(settings, dynamicValue, selectedDynamicValue);
      }
      this.replacedDynamicFormValues = this.replaceDynamicValues(dynamicFormValues, dynamicValue, selectedDynamicValue);
    });
  }

  /**
   * onRuleChange
   * @param {FilterRule} filterRule
   * @memberof AutomationTestActionComponent
   */
  public onRuleChange(filterRule: FilterRule) {
    this.filterRule = filterRule;
    // Prevent searching until all fields of the filter are initially filled in
    if (filterRule.isDraft && filterRule.isValid(this.dynamicValuesColumnsByName)) {
      filterRule.isDraft = false;
    }

    if (!filterRule.isDraft) {
      this.store.dispatch(
        AutomationCommonActions.loadTestDynamicValuesTable({
          pagedRequest: new PagedRequest({
            from: 0,
            size: 5,
          }),
          filterRule,
        }),
      );
    }
  }

  /**
   * openEditConnectorConfigModal
   *
   * @param {Connector} connector
   * @memberof AutomationTestActionComponent
   */
  public openEditConnectorConfigModal(connector: Connector) {
    this.closeModal();
    this.store.dispatch(
      ConnectorCommonActions.showModal({
        connector,
        modalType: ConnectorModalType.EDIT_CONFIG,
      }),
    );
  }

  /**
   * checkIfTestButtonDisabled
   * @returns {boolean}
   * @memberof AutomationTestActionComponent
   */
  private checkIfTestButtonDisabled(): boolean {
    if (this.isTestRunning) {
      return true;
    }
    if (this.testType === ConnectionTestType.AUTOMATION) {
      const isActionInvalid = !automationCommonHelpers.isAutomationConnectorActionValid(
        new AutomationAction({
          ...this.action,
          ...helpers.getSettingsFromFormsBySectionType(this.formsBySectionType),
        }),
        this.connectorAction?.sections,
      );
      return !this.action || isActionInvalid;
    }
    return this.isTestTemplateInvalid;
  }

  /**
   * replaceDynamicValues
   * @param {any} value
   * @param {string} dynamicValue
   * @param {string} selectedDynamicValue
   * @returns {any}
   * @memberof AutomationTestActionComponent
   */
  private replaceDynamicValues(value: any, dynamicValue: string, selectedDynamicValue: string): any {
    if (typeof value === 'string') {
      value = value.replace(new RegExp(dynamicValue, 'g'), this.selectedDynamicValueData[selectedDynamicValue]);
    } else if (Array.isArray(value)) {
      value = value.map((val: any) => {
        return this.replaceDynamicValues(val, dynamicValue, selectedDynamicValue);
      });
    } else if (typeof value === 'object' && value !== null) {
      Object.keys(value).forEach((valueKey: string) => {
        value[valueKey] = this.replaceDynamicValues(value[valueKey], dynamicValue, selectedDynamicValue);
      });
    }
    return value;
  }
}
