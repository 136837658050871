/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { KEY_CODES, LocalSearchComponent } from '@dpa/ui-common';
import { keyBy, toArray } from 'lodash-es';

import { ColumnLabelPipe, getColumnTooltipBadgeText, I18NService } from '@ws1c/intelligence-common';
import { getColumnToggleFilterMap } from '@ws1c/intelligence-core/store';
import { Column, COLUMN_NAMES, ColumnIndex, ColumnToggleFilter } from '@ws1c/intelligence-models';

/**
 * Key Selector Search Component
 * @export
 * @class KeySelectorSearchComponent
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-key-selector-search',
  templateUrl: 'key-selector-search.component.html',
  styleUrls: ['key-selector-search.component.scss'],
})
export class KeySelectorSearchComponent implements OnChanges, OnDestroy {
  @Input() public showColumnsFromInput: boolean;
  @Input() public allColumns: Column[];
  @Input() public allColumnsByName?: ColumnIndex = {};
  @Input() public selectedColumn?: Column = new Column();
  @Input() public isCrossCategory?: boolean = false;
  @Input() public showReset?: boolean = true;
  @Input() public selectCustomAttribute?: boolean = true;
  @Input() public placeholderTextKey: string = 'COMMON_ACTIONS.SEARCH';
  @Output() public columnChange: EventEmitter<Column> = new EventEmitter();
  @ViewChild('filterKeySelector', { read: ElementRef }) public filterKeySelectorElementRef: ElementRef;
  @ViewChild('localSearch', { read: ElementRef }) public localSearchElementRef: ElementRef;
  @ViewChild('localSearch') public localSearch: LocalSearchComponent;

  public show: boolean = false;
  public searchString: string;
  public keydownKey: string;
  public keydownTrigger: boolean = false;
  public allColumnIndex: ColumnIndex = {};
  public columnToggleFilterMap: Record<string, ColumnToggleFilter> = {};

  public readonly columnLabelPipe: ColumnLabelPipe = new ColumnLabelPipe();
  public readonly i18nService: I18NService = inject(I18NService);
  public readonly elRef: ElementRef = inject(ElementRef);
  public readonly cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  private timer: ReturnType<typeof setTimeout>;

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof KeySelectorSearchComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.allColumns?.currentValue) {
      this.allColumnIndex = keyBy(changes.allColumns.currentValue, COLUMN_NAMES.byName.attributeName);
      this.columnToggleFilterMap = getColumnToggleFilterMap(this.allColumnIndex);
    }
    if (changes.allColumnsByName?.currentValue) {
      this.allColumnIndex = changes.allColumnsByName?.currentValue;
      this.allColumns = toArray(this.allColumnIndex);
      this.columnToggleFilterMap = getColumnToggleFilterMap(this.allColumnIndex);
    }
  }

  /**
   * ngOnDestroy
   * @memberOf KeySelectorSearchComponent
   */
  public ngOnDestroy() {
    clearTimeout(this.timer);
  }

  /**
   * hideColumnSelectorOnKeyDown
   * @param {KeyboardEvent} keyboardEvent
   * @memberof KeySelectorSearchComponent
   */
  @HostListener('document:keydown', ['$event'])
  public hideColumnSelectorOnKeyDown(keyboardEvent: KeyboardEvent) {
    if (!this.show) {
      return;
    }

    if (keyboardEvent.key === KEY_CODES.ESC) {
      this.show = false;
      this.checkAndClearSelectedCloumn();
      return;
    }

    if (keyboardEvent.key === KEY_CODES.TAB) {
      // hide key-selector popup on tab out
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (!this.elRef.nativeElement.contains(document.activeElement)) {
          this.show = false;
          this.cdRef.detectChanges();
        }
      });
    }
  }

  /**
   * hideColumnSelectorOnClickOutside
   * @param {MouseEvent} event
   * @memberof KeySelectorSearchComponent
   */
  @HostListener('document:click', ['$event'])
  public hideColumnSelectorOnClickOutside(event: MouseEvent) {
    if (!this.show || !this.filterKeySelectorElementRef || !this.localSearchElementRef) {
      return;
    }
    this.show =
      this.filterKeySelectorElementRef.nativeElement.contains(event.target) ||
      this.localSearchElementRef.nativeElement.contains(event.target);
    if (!this.show) {
      this.checkAndClearSelectedCloumn();
    }
  }

  /**
   * checkAndClearSelectedCloumn
   * @memberof KeySelectorSearchComponent
   */
  public checkAndClearSelectedCloumn() {
    // Clear selected column if search string is not the same as selected label
    if (this.searchString !== this.getSelectedColumnLabel()) {
      this.onColumnChange(undefined);
    }
  }

  /**
   * onColumnChange
   * @param {Column} column
   * @memberof KeySelectorSearchComponent
   */
  public onColumnChange(column: Column) {
    this.setSearchString(this.getSelectedColumnLabel());
    this.show = false;
    this.columnChange.emit(column);
  }

  /**
   * setSearchString
   * @param {string} searchString
   * @memberof KeySelectorSearchComponent
   */
  public setSearchString(searchString: string) {
    this.searchString = searchString;
    this.show = true;
  }

  /**
   * showKeySelector
   * @param {string} value
   * @memberof KeySelectorSearchComponent
   */
  public showKeySelector(value: string) {
    this.show = true;
    const selectedColumn = this.getSelectedColumn();
    const selectedColumnLabel = this.columnLabelPipe.transform(
      selectedColumn?.attribute?.fullyQualifiedName,
      this.allColumnIndex,
      this.columnToggleFilterMap,
    );
    this.setSearchString(selectedColumnLabel || value);
  }

  /**
   * onKeydownEvent
   * @param {KeyboardEvent} keyboardEvent
   * @memberof KeySelectorSearchComponent
   */
  public onKeydownEvent(keyboardEvent: KeyboardEvent) {
    this.keydownKey = keyboardEvent.key;
    this.keydownTrigger = !this.keydownTrigger;
  }

  /**
   * getSelectedColumn
   * @returns {Column}
   * @memberof KeySelectorSearchComponent
   */
  public getSelectedColumn(): Column {
    return this.selectedColumn?.attribute && this.allColumnIndex[this.selectedColumn.attribute.fullyQualifiedName];
  }

  /**
   * getSelectedColumnLabel
   * @returns {string}
   * @memberof KeySelectorSearchComponent
   */
  public getSelectedColumnLabel(): string {
    const selectedColumn = this.getSelectedColumn();
    return selectedColumn?.label ?? '';
  }

  /**
   * getSelectedColumnFullLabel
   * @returns {string}
   * @memberof KeySelectorSearchComponent
   */
  public getSelectedColumnFullLabel(): string {
    const selectedColumn = this.getSelectedColumn();
    const selectedColumnLabel = this.columnLabelPipe.transform(
      selectedColumn?.attribute?.fullyQualifiedName,
      this.allColumnIndex,
      this.columnToggleFilterMap,
    );
    const columnBadgeText: string = getColumnTooltipBadgeText(
      selectedColumn,
      this.allColumnIndex,
      this.columnToggleFilterMap,
      this.i18nService,
    );
    return !selectedColumn?.label ? '' : `${selectedColumnLabel} ${columnBadgeText}`;
  }

  /**
   * focusInput - allows focusing the input from outside this component
   * @memberof KeySelectorSearchComponent
   */
  public focusInput() {
    this.localSearch.focusInput();
  }
}
