/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppConfig } from '@ws1c/intelligence-common';
import { FilterGroupRuleComponent } from '@ws1c/intelligence-core/components/query-builder/filter-group-rule/filter-group-rule.component';
import { CoreAppState, UserPreferenceFeatureControlsSelectors } from '@ws1c/intelligence-core/store';
import {
  Category,
  Column,
  ColumnIndex,
  FilterRule,
  OrgTreeNode,
  RuleGroup,
  RuleStatus,
  SuggestionFilterBy,
} from '@ws1c/intelligence-models';

/**
 * FilterGroupDefaultViewComponent
 * @export
 * @class FilterGroupDefaultViewComponent
 */
@Component({
  selector: 'dpa-filter-group-default-view',
  templateUrl: 'filter-group-default-view.component.html',
  styleUrls: ['filter-group-default-view.component.scss'],
})
export class FilterGroupDefaultViewComponent {
  @Input() public editable: boolean = true;
  @Input() public ruleGroup: RuleGroup;
  @Input() public allColumnsByName: ColumnIndex = {};
  @Input() public filterColumnsByName?: ColumnIndex;
  @Input() public visibleColumnsSortedByName: Column[];
  @Input() public isCrossCategory: boolean = false;
  @Input() public suggestionCategory?: Category;
  @Input() public suggestionFilterBys?: SuggestionFilterBy[];
  @Input() public showThreeColumnFilter?: boolean = true;
  @Input() public alwaysShowKeySelector?: boolean = true;
  @Input() public delimiterSupported?: boolean = false;
  @Input() public showColumnsFromInput?: boolean = false;

  @Output() public onChange = new EventEmitter<RuleGroup>();

  @ViewChildren(FilterGroupRuleComponent) public filterGroupRuleComponents: QueryList<FilterGroupRuleComponent>;

  public readonly MAX_ALLOWED_RULE_NESTING_LEVELS = AppConfig.MAX_ALLOWED_RULE_NESTING_LEVELS;
  public readonly MAX_ALLOWED_RULES_PER_FILTER = AppConfig.MAX_ALLOWED_RULES_PER_FILTER;
  public readonly RULE_STATUS: any = RuleStatus;

  public isNestedGroupsFilterEnabled$: Observable<boolean>;
  public allowAddRule: boolean = true;
  public allowAddRuleGroup: boolean = true;
  public focusedRule: FilterRule;

  public orgHierarchy: OrgTreeNode;

  /**
   * Creates an instance of FilterGroupDefaultViewComponent.
   * @param {Store<CoreAppState>} store
   * @memberof FilterGroupDefaultViewComponent
   */
  constructor(private store: Store<CoreAppState>) {
    this.isNestedGroupsFilterEnabled$ = this.store.select(UserPreferenceFeatureControlsSelectors.isNestedGroupsFilterEnabled);
  }

  /**
   * getNewRule
   * @returns {FilterRule}
   * @memberof FilterGroupDefaultViewComponent
   */
  public getNewRule = () => new FilterRule();

  /**
   * getNewRuleGroup
   * @returns {RuleGroup}
   * @memberof FilterGroupDefaultViewComponent
   */
  public getNewRuleGroup = () => new RuleGroup();

  /**
   * isRuleActive
   * @param {FilterRule} rule
   * @returns {boolean}
   * @memberof FilterGroupDefaultViewComponent
   */
  public isRuleActive = (rule: FilterRule) => this.focusedRule === rule;

  /**
   * isRuleInValid
   * @param {FilterRule} rule
   * @returns {boolean}
   * @memberof FilterGroupDefaultViewComponent
   */
  public isRuleInValid = (rule: FilterRule) => rule.getStatus(this.allColumnsByName) === this.RULE_STATUS.INVALID;

  /**
   * onQueryChange
   * @param {RuleGroup} group
   * @memberof FilterGroupDefaultViewComponent
   */
  public onQueryChange(group: RuleGroup) {
    const newRuleGroup = new RuleGroup({
      rules: [...group.rules],
      operator: group.operator,
    });
    this.onChange.emit(newRuleGroup);
  }

  /**
   * onFocus
   * @param {FilterRule} rule
   * @param {FocusEvent} event
   * @memberof FilterGroupDefaultViewComponent
   */
  public onFocus(rule: FilterRule, event: FocusEvent) {
    const currentTarget = event.currentTarget as HTMLElement;
    if (currentTarget?.querySelector('dpa-custom-attribute-filter-group')) {
      return;
    }
    this.focusedRule = rule;
  }

  /**
   * onFocusOut
   * @param {FilterRule} rule
   * @param {FocusEvent} event
   * @memberof FilterGroupDefaultViewComponent
   */
  public onFocusOut(rule: FilterRule, event: FocusEvent) {
    const currentTarget = event.currentTarget as HTMLElement;
    const relatedTarget = event.relatedTarget as HTMLElement;

    // when switching from one field to another in the same group, ignore onFocusOut event
    if (relatedTarget && currentTarget.contains(relatedTarget)) {
      return;
    }

    // Fix for ExpressionChangedAfterItHasBeenCheckedError which is happening on pressing [ESC] key when
    // filter group key slector dropdown is open and focussed.
    setTimeout(() => {
      this.focusedRule = null;
    }, 100);
    rule.isDraft = false;
  }

  /**
   * focusSelectedRule
   * @param {FilterRule} rule
   * @memberof FilterGroupDefaultViewComponent
   */
  public focusSelectedRule(rule: FilterRule) {
    const filterGroupRuleComponent = this.filterGroupRuleComponents.find((item: FilterGroupRuleComponent) => {
      return item.rule === rule;
    });
    filterGroupRuleComponent?.focusLeadTypeAhead();
  }

  /**
   * trackByFn
   * @param {number} index
   * @param {FilterRule} rule
   * @returns {string}
   * @memberof FilterGroupDefaultViewComponent
   */
  public trackByFn(index: number, rule: FilterRule): string {
    return `${index}-${rule?.attribute ?? ''}`;
  }

  /**
   * isRuleValid
   * @param {FilterRule} rule
   * @returns {boolean}
   * @memberof FilterGroupRuleComponent
   */
  public isRuleValid(rule: FilterRule): boolean {
    return rule.isValid(this.allColumnsByName);
  }

  /**
   * enableRuleEdit
   * @param {FilterRule} rule
   * @param {HTMLElement} filterRuleNode
   * @memberof FilterGroupRuleComponent
   */
  public enableRuleEdit(rule: FilterRule, filterRuleNode: HTMLElement) {
    this.focusedRule = rule;
    setTimeout(() => {
      (filterRuleNode.querySelector('dpa-filter-group-rule') as any)?.focus();
    }, 30);
  }

  /**
   * onValuesSelect
   * @param {FilterRule} rule
   * @param {HTMLElement} filterRuleNode
   * @memberof FilterGroupRuleComponent
   */
  public onValuesSelect(rule: FilterRule, filterRuleNode: HTMLElement) {
    this.focusedRule = rule;
    setTimeout(() => {
      (filterRuleNode.querySelector('dpa-filter-group-rule dpa-filter-value input') as any)?.focus();
    }, 30);
  }
}
