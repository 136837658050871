/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { DashboardCommonModule } from '@ws1c/dashboard-common/dashboard-common.module';
import { ColumnSelectorDetailComponent, IntelligenceCommonModule } from '@ws1c/intelligence-common';
import { INTELLIGENCE_CORE_DIRECTIVES } from '@ws1c/intelligence-core/directives';
import { CustomAttributeSelectorComponent } from './custom-attribute-selector/custom-attribute-selector.component';
import { CustomAttributeFilterGroupComponent } from './query-builder/custom-attribute-filter-group/custom-attribute-filter-group.component';
import { FilterValueComponent } from './query-builder/filter-value/filter-value.component';
import { RuleTextComponent } from './query-builder/rule-text/rule-text.component';
import { INTELLIGENCE_CORE_COMPONENTS, WidgetIncrementalLoadingStatusComponent } from './index';

/**
 * IntelligenceCoreComponentsModule
 *
 * @export
 * @class IntelligenceCoreComponentsModule
 */
@NgModule({
  declarations: [...INTELLIGENCE_CORE_COMPONENTS, ...INTELLIGENCE_CORE_DIRECTIVES],
  imports: [
    DashboardCommonModule,
    IntelligenceCommonModule,
    OverlayModule,
    NgxJsonViewerModule,
    ColumnSelectorDetailComponent,
    WidgetIncrementalLoadingStatusComponent,
    CustomAttributeSelectorComponent,
    CustomAttributeFilterGroupComponent,
    FilterValueComponent,
    RuleTextComponent,
  ],
  exports: [...INTELLIGENCE_CORE_COMPONENTS, ...INTELLIGENCE_CORE_DIRECTIVES, WidgetIncrementalLoadingStatusComponent],
})
export class IntelligenceCoreComponentsModule {}
