/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { BreadCrumb, DataGridComponent, getFailureReason, SortOn, WebError, WebErrorMessage } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { map as _map, reduce } from 'lodash-es';
import { merge, Observable, of, TimeoutError } from 'rxjs';
import { catchError, filter, map, switchMap, tap, timeout, withLatestFrom } from 'rxjs/operators';

import { DEEM_ROUTE_NAMES } from '@ws1c/deem-solution/const';
import { AppConfig, I18NService, RouterExtensions } from '@ws1c/intelligence-common';
import { AutomationService } from '@ws1c/intelligence-core/services';
import {
  AlertBannerActions,
  AutomationCommonActions,
  AutomationCommonSelectors,
  BookmarksActions,
  BookmarksSelectors,
  ConnectorCommonSelectors,
  CoreAppState,
  FeatureSelectors,
  getLookupFieldNameValue,
  NavigationActions,
  UserPreferenceActions,
  UserPreferenceFeatureControlsSelectors,
} from '@ws1c/intelligence-core/store';
import {
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  Automation,
  AUTOMATION_TYPE_KEY_MAP,
  AutomationAction,
  AutomationActionFieldLookupResponse,
  AutomationActionFieldLookupResponseData,
  AutomationActionLookupRequest,
  AutomationActionSettingsSection,
  AutomationQuota,
  AutomationSchedule,
  AutomationSearchRequest,
  AutomationSearchResponse,
  AutomationSourceObject,
  AutomationTestActionRequest,
  AutomationTestActionResponse,
  AutomationTestActionResult,
  AutomationType,
  BlockerStatus,
  Bookmark,
  COLUMN_NAMES,
  Connector,
  ConnectorAction,
  ConnectorActionSection,
  CronExpressionData,
  DeemIncident,
  EvaluationType,
  getTestResultType,
  GraphNodeType,
  NameValue,
  ResultState,
  ROUTE_NAMES,
  setSelectedNodeId,
  SourceObjectType,
  UIPreference,
} from '@ws1c/intelligence-models';

/**
 * AutomationCommonEffects
 *
 * @export
 * @class AutomationCommonEffects
 */
@Injectable()
export class AutomationCommonEffects {
  /**
   * getAutomationQuotas$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public getAutomationQuotas$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.getAutomationQuotas),
      switchMap(() =>
        this.automationService.getAutomationQuotas().pipe(
          map((automationQuotas: AutomationQuota[]) => AutomationCommonActions.getAutomationQuotasSuccess({ automationQuotas })),
          catchError((error: WebError) => of(AutomationCommonActions.getAutomationQuotasFailure({ error }))),
        ),
      ),
    ),
  );

  /**
   * showAutomationDailyEmailCapacityReachedAlert$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public showAutomationDailyEmailCapacityReachedAlert$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.getAutomationQuotasSuccess),
      withLatestFrom(
        this.store.select(UserPreferenceFeatureControlsSelectors.isAutomationQuotaUsageEnabled),
        this.store.select(AutomationCommonSelectors.getAutomationDailyEmailCapacity),
        this.routerExtensions.url$,
      ),
      filter(
        ([_action, isAutomationQuotaUsageEnabled, automationDailyEmailCapacity, navigationUrl]: [
          Action,
          boolean,
          AutomationQuota,
          string,
        ]) => {
          return (
            isAutomationQuotaUsageEnabled &&
            automationDailyEmailCapacity &&
            automationDailyEmailCapacity.isQuotaReached &&
            navigationUrl !== `/${ROUTE_NAMES.ORGANIZATION}`
          );
        },
      ),
      switchMap(() =>
        of(
          AlertBannerActions.showAlertBanner({
            alertType: ALERT_BANNER_TYPE.DANGER,
            target: AlertBannerTarget.APP,
            actionLink: this.routerExtensions.getNavigationUrl([`/${ROUTE_NAMES.ORGANIZATION}`]),
            actionText: this.i18nService.translate('CURRENT_USER.DAILY_WORKFLOW_EMAIL_CAPACITY_ALERT_ACTION_MESSAGE'),
            message: this.i18nService.translate('CURRENT_USER.DAILY_WORKFLOW_EMAIL_CAPACITY_ALERT_MESSAGE'),
          }),
        ),
      ),
    ),
  );

  /**
   * automationFromOtherSource$
   *
   * @memberof AutomationCommonEffects
   */
  public automationFromOtherSource$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AutomationCommonActions.automateFromDashboard,
          AutomationCommonActions.automateFromStandardDashboard,
          AutomationCommonActions.automateFromWidgetDetails,
          AutomationCommonActions.automateFromReportList,
          AutomationCommonActions.automateFromReportDetails,
          AutomationCommonActions.automateFromDataExplorer,
        ),
        tap(() => this.routerExtensions.navigate([ROUTE_NAMES.GLOBAL_ORCHESTRATOR.ADD_WORKFLOW])),
      ),
    { dispatch: false },
  );

  /**
   * automateFromSourceObject
   * @memberof AutomationCommonEffects
   */
  public automateFromSourceObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.automateFromSourceObject),
      map((sourceObject: ReturnType<typeof AutomationCommonActions.automateFromSourceObject>) => {
        const route = ROUTE_NAMES.GLOBAL_ORCHESTRATOR.ADD_WORKFLOW;
        // `queryParams` must be flat
        this.routerExtensions.navigate([route], {
          queryParams: {
            sourceObjectId: sourceObject.sourceObjectId,
            sourceObjectType: sourceObject.sourceObjectType,
            ...sourceObject.params,
          },
        });
        return AutomationCommonActions.setSourceObject(sourceObject);
      }),
    ),
  );

  /**
   * loadAutomation$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public loadAutomation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.loadAutomation),
      // automations without id should not be loaded
      filter(({ automation }: ReturnType<typeof AutomationCommonActions.loadAutomation>) => {
        return !!(automation && automation.id);
      }),
      switchMap(({ automation }: ReturnType<typeof AutomationCommonActions.loadAutomation>) => {
        return this.automationService
          .getAutomation(automation.id)
          .pipe(map((response: Automation) => AutomationCommonActions.loadAutomationSuccess({ automation: response })));
      }),
    ),
  );

  /**
   * loadMoreAutomations$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public loadMoreAutomations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.loadMoreAutomations),
      switchMap(({ automationSearchRequest }: ReturnType<typeof AutomationCommonActions.loadMoreAutomations>) => {
        return this.automationService.getAutomations(automationSearchRequest).pipe(
          map((response: AutomationSearchResponse) => AutomationCommonActions.loadMoreAutomationsSuccess({ response })),
          catchError((error: WebError) => of(AutomationCommonActions.loadAutomationListFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * confirmRenameAutomation$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public confirmRenameAutomation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.confirmRenameAutomation),
      withLatestFrom(
        this.store.select(AutomationCommonSelectors.getAutomationDialogModel),
        this.store.select(AutomationCommonSelectors.getActiveAutomationType),
      ),
      switchMap(
        ([{ newAutomationName, fromDetailView }, automationDialogModel, activeAutomationType]: [
          ReturnType<typeof AutomationCommonActions.confirmRenameAutomation>,
          Automation,
          AutomationType,
        ]) => {
          const oldName = automationDialogModel.name;
          const renamedAutomation = new Automation({
            ...automationDialogModel,
            name: newAutomationName,
          });

          return merge(
            this.automationService.renameAutomation(renamedAutomation).pipe(
              switchMap((updatedAutomation: Automation) => {
                return fromDetailView
                  ? [
                      AutomationCommonActions.updateAutomationSuccess({ automation: updatedAutomation }),
                      // If coming from the details page, just update the store with the new name and stay on the same page
                      AutomationCommonActions.setAutomationDetails({ automation: updatedAutomation }),
                    ]
                  : [
                      AutomationCommonActions.updateAutomationSuccess({ automation: updatedAutomation }),
                      AlertBannerActions.showAlertBanner({
                        alertType: ALERT_BANNER_TYPE.SUCCESS,
                        message: this.i18nService.translate(
                          `WORKFLOWS.RENAME_${AUTOMATION_TYPE_KEY_MAP[activeAutomationType]}_SUCCESS_MSG`,
                          {
                            oldName,
                            newName: newAutomationName,
                          },
                        ),
                      }),
                      AutomationCommonActions.refreshAutomations(),
                    ];
              }),
              catchError((error: WebError) => [
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  message: this.i18nService.translate(`WORKFLOWS.RENAME_${AUTOMATION_TYPE_KEY_MAP[activeAutomationType]}_ERROR_MSG`, {
                    reason: error.getFullReason(),
                  }),
                }),
              ]),
            ),
            of(AutomationCommonActions.closeAutomationDialog()),
          );
        },
      ),
    ),
  );

  /**
   * loadAutomationList$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public loadAutomationList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.loadAutomationList),
      withLatestFrom(this.store.select(AutomationCommonSelectors.getActiveAutomationType)),
      switchMap(([{ request }, automationType]: [ReturnType<typeof AutomationCommonActions.loadAutomationList>, AutomationType]) => {
        return this.automationService.getAutomations(request, automationType).pipe(
          map((response: AutomationSearchResponse) => AutomationCommonActions.loadAutomationListSuccess({ response })),
          catchError((error: WebError) => of(AutomationCommonActions.loadAutomationListFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * updateAutomationSuccess$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public updateAutomationSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.updateAutomationSuccess),
      map((action: ReturnType<typeof AutomationCommonActions.updateAutomationSuccess>) => action.automation),
      withLatestFrom(this.store.select(BookmarksSelectors.getActiveBookmarksMap)),
      filter(([automation, activeBookmarksMap]: [Automation, Map<string, Bookmark>]) => activeBookmarksMap.has(automation.id)),
      switchMap(([automation, activeBookmarksMap]: [Automation, Map<string, Bookmark>]) => {
        const bookmark: Bookmark = activeBookmarksMap.get(automation.id);
        return of(
          BookmarksActions.updateBookmark({
            bookmark: new Bookmark({
              ...bookmark,
              title: automation.name,
            }),
          }),
        );
      }),
    ),
  );

  /**
   * updateAutomationList$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public updateAutomationList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AutomationCommonActions.changePagination,
        AutomationCommonActions.refreshAutomations,
        AutomationCommonActions.filterAutomations,
        AutomationCommonActions.sortAutomations,
      ),
      withLatestFrom(
        this.store.select(AutomationCommonSelectors.getAutomationSearchRequest),
        this.store.select(AutomationCommonSelectors.getSourceObject),
      ),
      switchMap(([action, pagedRequest, sourceObject]: [any, AutomationSearchRequest, AutomationSourceObject]) => {
        const actions: Action[] = [];
        if (sourceObject) {
          actions.push(
            AutomationCommonActions.loadAutomationsBySourceObjectId({
              sourceObjectId: sourceObject.sourceObjectId,
              pagedRequest,
            }),
          );
          return actions;
        }
        actions.push(AutomationCommonActions.loadAutomationList({ request: pagedRequest }));
        if (action.sortOns?.length) {
          actions.push(
            UserPreferenceActions.updateUISettings({
              uiSettings: {
                [UIPreference.WORKFLOW_VIEW_SORT]: [Object.assign({}, action.sortOns[0])],
              },
            }),
          );
        }
        return actions;
      }),
    ),
  );

  /**
   * goToAutomationPage$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public goToAutomationPage$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AutomationCommonActions.goToAutomationPage),
        tap(({ automationType }: ReturnType<typeof AutomationCommonActions.goToAutomationPage>) => {
          switch (automationType) {
            case AutomationType.QUICKFLOWS: {
              return this.routerExtensions.navigate([`/${ROUTE_NAMES.AUTOMATION.AUTOMATION_QUICKFLOW}`]);
            }
            default: {
              return this.routerExtensions.navigate([`/${ROUTE_NAMES.AUTOMATION.AUTOMATION_WORKFLOW}`]);
            }
          }
        }),
      ),
    { dispatch: false },
  );

  /**
   * goToAutomationDetailsPage$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public goToAutomationDetailsPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AutomationCommonActions.goToAutomationDetailsPage),
        tap(({ automationId, automationType }: ReturnType<typeof AutomationCommonActions.goToAutomationDetailsPage>) => {
          switch (automationType) {
            case AutomationType.QUICKFLOWS: {
              return this.routerExtensions.navigate([`/${ROUTE_NAMES.AUTOMATION.AUTOMATION_QUICKFLOW}/${automationId}`]);
            }
            default: {
              return this.routerExtensions.navigate([`/${ROUTE_NAMES.AUTOMATION.AUTOMATION_WORKFLOW}/${automationId}`]);
            }
          }
        }),
      ),
    { dispatch: false },
  );

  /**
   * goToEditAutomationPage$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public goToEditAutomationPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AutomationCommonActions.goToEditAutomationPage),
        tap(({ automationId, automationType }: ReturnType<typeof AutomationCommonActions.goToEditAutomationPage>) => {
          const path = ROUTE_NAMES.GLOBAL_ORCHESTRATOR.AUTOMATION_WORKFLOW;
          return this.routerExtensions.navigate([`${path}/${automationId}/${ROUTE_NAMES.COMMON.EDIT}`], {
            queryParams: automationType ? { [AppConfig.QUERY_PARAM_TYPE]: automationType } : {},
          });
        }),
      ),
    { dispatch: false },
  );

  /**
   * editFromOtherSource$
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public editFromOtherSource$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.editFromOtherSource),
      switchMap(({ automationId, cancelReturnPath, successReturnPath }: ReturnType<typeof AutomationCommonActions.editFromOtherSource>) => {
        return [
          AutomationCommonActions.setCancelReturnPath({ cancelWizardReturnPath: cancelReturnPath }),
          AutomationCommonActions.setSuccessReturnPath({ successWizardReturnPath: successReturnPath }),
          AutomationCommonActions.goToEditAutomationPage({ automationId }),
        ];
      }),
    ),
  );

  /**
   * changeAutomationStatus$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public changeAutomationStatus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.changeAutomationStatus),
      withLatestFrom(
        this.store.select(AutomationCommonSelectors.getAutomationDialogModel),
        this.store.select(AutomationCommonSelectors.getActiveAutomationType),
      ),
      switchMap(
        ([{ fromDetailView, isOneTimeManualRunEnabled }, automationDialogModel, activeAutomationType]: [
          ReturnType<typeof AutomationCommonActions.changeAutomationStatus>,
          Automation,
          AutomationType,
        ]) => {
          const active: boolean = automationDialogModel ? !automationDialogModel.active : false;

          const newAutomation = new Automation({ ...automationDialogModel, active });

          const stream$: Observable<Automation> = active
            ? this.automationService.enableAutomation(newAutomation)
            : this.automationService.disableAutomation(newAutomation);

          return merge(
            stream$.pipe(
              switchMap((updatedAutomation: Automation) => {
                const actions: Action[] = [
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.SUCCESS,
                    message: this.i18nService.translate(
                      'WORKFLOWS.' + AUTOMATION_TYPE_KEY_MAP[activeAutomationType] + '_STATUS_CHANGE_SUCCESS',
                    ),
                  }),
                ];
                if (fromDetailView) {
                  // If coming from the details page, just update the store with the new active status and stay on the same page
                  actions.push(AutomationCommonActions.setAutomationDetails({ automation: updatedAutomation }));
                } else {
                  actions.push(AutomationCommonActions.refreshAutomations());
                }
                if (isOneTimeManualRunEnabled) {
                  actions.push(AutomationCommonActions.confirmRunAutomation({ automation: updatedAutomation }));
                }
                return actions;
              }),
              catchError((error: WebError) => [
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  message: this.i18nService.translate(
                    'WORKFLOWS.' + AUTOMATION_TYPE_KEY_MAP[activeAutomationType] + '_STATUS_CHANGE_FAILURE',
                    {
                      reason: error.getFullReason(),
                    },
                  ),
                }),
              ]),
            ),
            of(AutomationCommonActions.closeAutomationDialog()),
          );
        },
      ),
    ),
  );

  /**
   * confirmCopyAutomation$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public confirmCopyAutomation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.confirmCopyAutomation),
      withLatestFrom(
        this.store.select(AutomationCommonSelectors.getAutomationDialogModel),
        this.store.select(AutomationCommonSelectors.getActiveAutomationType),
      ),
      switchMap(
        ([{ newAutomationName, fromDetailView }, automationDialogModel, activeAutomationType]: [
          ReturnType<typeof AutomationCommonActions.confirmCopyAutomation>,
          Automation,
          AutomationType,
        ]) => {
          const oldName = automationDialogModel.name;
          const copiedAutomation = Object.assign({}, automationDialogModel, { name: newAutomationName });
          return merge(
            this.automationService.cloneAutomation(copiedAutomation).pipe(
              switchMap((newAutomation: Automation) => {
                const actions: Action[] = [
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.SUCCESS,
                    message: this.i18nService.translate(
                      `WORKFLOWS.DUPLICATE_${AUTOMATION_TYPE_KEY_MAP[activeAutomationType]}_SUCCESS_MSG`,
                      {
                        oldName,
                        newName: newAutomationName,
                      },
                    ),
                  }),
                ];
                if (fromDetailView) {
                  // If coming from the details page, redirect to the details page for the new automation copy
                  actions.push(
                    AutomationCommonActions.goToAutomationDetailsPage({
                      automationId: newAutomation.id,
                      automationType: activeAutomationType,
                    }),
                  );
                } else {
                  actions.push(AutomationCommonActions.refreshAutomations());
                }
                return actions;
              }),
              catchError((error: WebError) => [
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  message: this.i18nService.translate(`WORKFLOWS.DUPLICATE_${AUTOMATION_TYPE_KEY_MAP[activeAutomationType]}_ERROR_MSG`, {
                    reason: getFailureReason(error),
                  }),
                }),
              ]),
            ),
            of(AutomationCommonActions.closeAutomationDialog()),
          );
        },
      ),
    ),
  );

  /**
   * confirmDeleteAutomation$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public confirmDeleteAutomation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.confirmDeleteAutomation),
      withLatestFrom(
        this.store.select(AutomationCommonSelectors.getAutomationDialogModel),
        this.store.select(AutomationCommonSelectors.getActiveAutomationType),
      ),
      switchMap(
        ([{ fromDetailView }, automationDialogModel, activeAutomationType]: [
          ReturnType<typeof AutomationCommonActions.confirmDeleteAutomation>,
          Automation,
          AutomationType,
        ]) => {
          const name = automationDialogModel.name;

          return merge(
            this.automationService.deleteAutomation(automationDialogModel.id).pipe(
              switchMap(() => {
                const actions: Action[] = [
                  AutomationCommonActions.confirmDeleteAutomationSuccess({ automation: automationDialogModel }),
                  AlertBannerActions.showAlertBanner({
                    alertType: ALERT_BANNER_TYPE.SUCCESS,
                    message: this.i18nService.translate(`WORKFLOWS.DELETE_${AUTOMATION_TYPE_KEY_MAP[activeAutomationType]}_SUCCESS_MSG`, {
                      name,
                    }),
                  }),
                ];
                if (fromDetailView) {
                  // If coming from the details page, need to head back to the automation listing page after the delete
                  actions.push(AutomationCommonActions.goToAutomationPage({ automationType: activeAutomationType }));
                } else {
                  actions.push(AutomationCommonActions.refreshAutomations());
                }
                return actions;
              }),
              catchError((reason) => [
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  message: this.i18nService.translate(`WORKFLOWS.DELETE_${AUTOMATION_TYPE_KEY_MAP[activeAutomationType]}_ERROR_MSG`, {
                    reason,
                  }),
                }),
              ]),
            ),
            of(AutomationCommonActions.closeAutomationDialog()),
          );
        },
      ),
    ),
  );

  /**
   * confirmDeleteAutomationSuccess$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public confirmDeleteAutomationSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.confirmDeleteAutomationSuccess),
      withLatestFrom(this.store.select(BookmarksSelectors.getActiveBookmarksMap)),
      filter(
        ([{ automation }, activeBookmarksMap]: [
          ReturnType<typeof AutomationCommonActions.confirmDeleteAutomationSuccess>,
          Map<string, Bookmark>,
        ]) => activeBookmarksMap.has(automation.id),
      ),
      switchMap(([{ automation }]: [ReturnType<typeof AutomationCommonActions.confirmDeleteAutomationSuccess>, Map<string, Bookmark>]) => {
        return of(BookmarksActions.removeBookmarksFromCache({ resourceIds: [automation.id] }));
      }),
    ),
  );

  /**
   * confirmRunAutomation$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public confirmRunAutomation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.confirmRunAutomation),
      switchMap(({ automation }: ReturnType<typeof AutomationCommonActions.confirmRunAutomation>) => {
        const name = automation.name;
        return merge(
          this.automationService.executeAutomation(automation.id).pipe(
            switchMap(() => [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.SUCCESS,
                message: this.i18nService.translate('WORKFLOWS.RUN_WORKFLOW_SUCCESS_MSG', { name }),
              }),
            ]),
            catchError((error: WebError) => [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                message: this.i18nService.translate('WORKFLOWS.RUN_WORKFLOW_ERROR_MSG', {
                  reason: error.getFullReason(),
                }),
              }),
            ]),
          ),
          of(AutomationCommonActions.closeAutomationDialog()),
        );
      }),
    ),
  );

  /**
   * fetchAutomationAndRunAction$
   * Fetch Automation via API and invoke the next action using the response as payload
   * Used in the Automation Grid for Run/Enable/Disable actions
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public fetchAutomationAndRunAction$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.fetchAutomationAndRunAction),
      switchMap(({ id, nextAction }: ReturnType<typeof AutomationCommonActions.fetchAutomationAndRunAction>) => {
        return this.automationService.getAutomation(id).pipe(map((automation: Automation) => nextAction({ automation })));
      }),
    ),
  );

  /**
   * addTriggerToWizardModel$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public addTriggerToWizardModel$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.addTriggerToWizardModel),
      withLatestFrom(this.store.select(FeatureSelectors.hasReportReadOnlyPerm)),
      filter(([_, hasReportPerm]: [ReturnType<typeof AutomationCommonActions.addTriggerToWizardModel>, boolean]) => hasReportPerm),
      switchMap(() => {
        return of(AutomationCommonActions.clearAutomationPreviewData());
      }),
    ),
  );

  /**
   * testConnectorAction$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public testConnectorAction$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.testConnectorAction),
      withLatestFrom(this.store.select(ConnectorCommonSelectors.getConnectorsWithActions)),
      switchMap(([{ actionTest }, connectors]: [ReturnType<typeof AutomationCommonActions.testConnectorAction>, Connector[]]) => {
        const actionConnector = connectors?.find((connector: Connector) => connector.id === actionTest.serviceId);
        const actionTestPayload = new AutomationTestActionRequest({
          actionTemplateId: actionTest.actionTemplateId,
          actionType: actionTest.serviceAction.action.key,
          dataBySection: actionTest.serviceAction.action.dataBySection,
          connectorConfigId: actionConnector?.configId,
          connectorIntegration: actionConnector?.integration,
        });
        return this.automationService.testAutomationConnectorAction(actionTestPayload).pipe(
          switchMap((response: AutomationTestActionResponse) => {
            const testResult = new AutomationTestActionResult({
              alert: getTestResultType(response.responseStatusCode),
              success: response.resultState === ResultState.SUCCESS,
              messages: [`${response.responseStatusCode} ${response.responseReasonPhrase}`],
              errorMessage: response.errorMessage,
              rawResponse: response.rawResponse,
              executionStatus: response.resultState,
            });
            const testConnectionAction =
              response.resultState === ResultState.SUCCESS
                ? AutomationCommonActions.testConnectorActionSuccess({ actionTestResult: testResult })
                : AutomationCommonActions.testConnectorActionFailure({ actionTestResult: testResult });
            return [testConnectionAction];
          }),
          catchError((error: WebError) => {
            const reason = getFailureReason(error, '', this.i18nService.translate('COMMON_MESSAGES.INTERNAL_SERVER_ERROR'));
            return [
              AutomationCommonActions.testConnectorActionFailure({
                actionTestResult: new AutomationTestActionResult({
                  success: false,
                  messages: [reason],
                }),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * getIrrecoverableActions$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public getIrrecoverableActions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.getIrrecoverableActions),
      withLatestFrom(
        this.store.select(AutomationCommonSelectors.getAutomationWizardModel),
        this.store.select(AutomationCommonSelectors.getAutomationDialogModel),
      ),
      switchMap(([_action, wizardModel, dialogModel]: [Action, Automation, Automation]) => {
        if (!wizardModel) {
          return of(AutomationCommonActions.loadAutomation({ automation: dialogModel }));
        } else {
          return of({ type: 'none' });
        }
      }),
    ),
  );

  /**
   * loadAutomationsBySourceObjectId$
   * @type {Observable<Action>}
   * @memberOf AutomationCommonEffects
   */
  public loadAutomationsBySourceObjectId$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.loadAutomationsBySourceObjectId),
      switchMap(({ sourceObjectId, pagedRequest }: ReturnType<typeof AutomationCommonActions.loadAutomationsBySourceObjectId>) => {
        const request =
          pagedRequest ??
          new AutomationSearchRequest({
            from: 0,
            size: DataGridComponent.DEFAULT_PAGE_SIZE,
            sortOns: [
              new SortOn({
                by: COLUMN_NAMES.byName.modified_at,
                label: this.i18nService.translate('AUTOMATION_ACTIONS.LAST_MODIFIED_NEWEST'),
                reverse: true,
              }),
            ],
          });

        return this.automationService.getAutomations(request, AutomationType.GENERAL, sourceObjectId).pipe(
          map((response: AutomationSearchResponse) =>
            AutomationCommonActions.loadAutomationsBySourceObjectIdSuccess({ request, response }),
          ),
          catchError((error: WebError) => of(AutomationCommonActions.loadAutomationsBySourceObjectIdFailure({ error }))),
        );
      }),
    ),
  );

  /**
   * showSaveAndEnableDialog$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public showSaveAndEnableDialog$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.showSaveAndEnableDialog),
      withLatestFrom(this.store.select(AutomationCommonSelectors.getAutomationWizardModel)),
      map(([_action, wizardModel]: [Action, Automation]) => {
        if (wizardModel.active) {
          return AutomationCommonActions.showSaveAndEnableDialogSuccess();
        } else {
          return wizardModel.id ? AutomationCommonActions.confirmEditAutomation({}) : AutomationCommonActions.confirmCreateAutomation({});
        }
      }),
    ),
  );

  /**
   * showTriggerRules$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public setShowTriggerRules$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AutomationCommonActions.setShowTriggerRules),
        filter(({ showTriggerRules }: ReturnType<typeof AutomationCommonActions.setShowTriggerRules>) => showTriggerRules),
        tap(() => setSelectedNodeId(GraphNodeType.WORKFLOW_SETTINGS)),
      ),
    { dispatch: false },
  );

  /**
   * saveAutomation
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public saveAutomation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.saveAutomation),
      switchMap(({ automation }: ReturnType<typeof AutomationCommonActions.saveAutomation>) => {
        return automation.name
          ? of(AutomationCommonActions.automationSaveAndEnableConfirm({ isOneTimeManualRunEnabled: false, fromDetailView: false }))
          : of(AutomationCommonActions.showSaveDraftDialog());
      }),
    ),
  );

  /**
   * automationSaveAndEnableConfirm$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public automationSaveAndEnableConfirm$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.automationSaveAndEnableConfirm),
      withLatestFrom(this.store.select(AutomationCommonSelectors.getAutomationWizardModel)),
      switchMap(
        ([{ isOneTimeManualRunEnabled }, automationWizardModel]: [
          ReturnType<typeof AutomationCommonActions.automationSaveAndEnableConfirm>,
          Automation,
        ]) => {
          return automationWizardModel.id
            ? of(AutomationCommonActions.confirmEditAutomation({ isOneTimeManualRunEnabled }))
            : of(AutomationCommonActions.confirmCreateAutomation({ isOneTimeManualRunEnabled }));
        },
      ),
    ),
  );

  /**
   * navigateToSuccessReturnPath$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public navigateToSuccessReturnPath$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.navigateToSuccessReturnPath),
      withLatestFrom(this.store.select(AutomationCommonSelectors.getSuccessWizardReturnPath)),
      map(([{ automation }, returnPath]: [ReturnType<typeof AutomationCommonActions.navigateToSuccessReturnPath>, string]) => {
        if (!returnPath) {
          return AutomationCommonActions.goToAutomationPage({});
        }
        // automations with source objects will return sourceId and created automationId, actionId back to return path on success
        if (automation.hasSourceObject) {
          const surveyAction: AutomationAction = automation.actions[0];
          this.routerExtensions.navigate([returnPath], {
            queryParams: {
              id: automation.sourceObjectId,
              automationId: automation.id,
              actionId: surveyAction?.id || surveyAction?.actionData.settingsBySection[AutomationActionSettingsSection.SYSTEM].action_id,
            },
          });
        } else {
          this.routerExtensions.navigateByUrl(returnPath);
        }
        return false;
      }),
      filter((value: any) => !!value),
    ),
  );

  /**
   * confirmCreateAutomation$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public confirmCreateAutomation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.confirmCreateAutomation),
      withLatestFrom(
        this.store.select(AutomationCommonSelectors.getAutomationWizardModel),
        this.store.select(AutomationCommonSelectors.getSelectedCronExpressionData),
        this.store.select(AutomationCommonSelectors.getActiveAutomationType),
      ),
      switchMap(
        ([{ isOneTimeManualRunEnabled }, automationWizardModel, cronExpressionData, activeAutomationType]: [
          ReturnType<typeof AutomationCommonActions.confirmCreateAutomation>,
          Automation,
          CronExpressionData,
          AutomationType,
        ]) => {
          if (automationWizardModel.evaluationType === EvaluationType.SCHEDULED) {
            automationWizardModel = new Automation({
              ...automationWizardModel,
              schedule: AutomationSchedule.createFromAutomationAndScheduleDetails({ cronExpressionDetail: cronExpressionData }),
            });
          }

          return this.automationService.createAutomation(automationWizardModel).pipe(
            switchMap((createdAutomation: Automation) => {
              const actions: Action[] = [
                AutomationCommonActions.confirmCreateAutomationSuccess(),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  message: this.i18nService.translate(`WORKFLOWS.CREATE_${AUTOMATION_TYPE_KEY_MAP[activeAutomationType]}_SUCCESS_MSG`),
                }),
                NavigationActions.setBlockerStatus({ blockerStatus: BlockerStatus.SKIP_NEXT_BLOCK }),
                AutomationCommonActions.refreshAutomations(),
                AutomationCommonActions.closeAutomationDialog(),
                AutomationCommonActions.navigateToSuccessReturnPath({ automation: createdAutomation }),
              ];
              if (isOneTimeManualRunEnabled) {
                actions.push(
                  AutomationCommonActions.confirmRunAutomation({
                    automation: createdAutomation,
                  }),
                );
              }
              return actions;
            }),
            catchError((webError: WebError) => {
              const alertItems = (webError.errors || []).map((error: WebErrorMessage) => ({
                message: this.i18nService.translate(`WORKFLOWS.CREATE_${AUTOMATION_TYPE_KEY_MAP[activeAutomationType]}_ERROR_MSG`, {
                  reason: error.message,
                }),
                actionLink: error.actionLink,
                actionText: error.actionText ?? '',
              }));
              return [
                AutomationCommonActions.confirmCreateAutomationFailure(),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.APP,
                  autoDismiss: false,
                  alertItems,
                }),
                AutomationCommonActions.closeAutomationDialog(),
              ];
            }),
          );
        },
      ),
    ),
  );

  /**
   * confirmEditAutomation$
   *
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public confirmEditAutomation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.confirmEditAutomation),
      withLatestFrom(
        this.store.select(AutomationCommonSelectors.getAutomationWizardModel),
        this.store.select(AutomationCommonSelectors.fromDetailView),
        this.store.select(AutomationCommonSelectors.getSelectedCronExpressionData),
        this.store.select(AutomationCommonSelectors.getActiveAutomationType),
      ),
      switchMap(
        ([{ isOneTimeManualRunEnabled }, automationWizardModel, fromDetailView, cronExpressionData, activeAutomationType]: [
          ReturnType<typeof AutomationCommonActions.confirmEditAutomation>,
          Automation,
          boolean,
          CronExpressionData,
          AutomationType,
        ]) => {
          if (automationWizardModel.evaluationType === EvaluationType.SCHEDULED) {
            automationWizardModel = new Automation({
              ...automationWizardModel,
              schedule: AutomationSchedule.createFromAutomationAndScheduleDetails(
                { cronExpressionDetail: cronExpressionData },
                automationWizardModel.schedule?.id,
              ),
            });
          }

          return this.automationService.updateAutomation(automationWizardModel).pipe(
            switchMap((updatedAutomation: Automation) => {
              const actions: Action[] = [
                AutomationCommonActions.updateAutomationSuccess({ automation: updatedAutomation }),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.SUCCESS,
                  message: this.i18nService.translate(`WORKFLOWS.EDIT_${AUTOMATION_TYPE_KEY_MAP[activeAutomationType]}_SUCCESS_MSG`),
                }),
                NavigationActions.setBlockerStatus({ blockerStatus: BlockerStatus.SKIP_NEXT_BLOCK }),
                AutomationCommonActions.closeAutomationDialog(),
              ];
              if (isOneTimeManualRunEnabled) {
                actions.push(
                  AutomationCommonActions.confirmRunAutomation({
                    automation: updatedAutomation,
                  }),
                );
              }
              if (fromDetailView) {
                // If the edit started from the details page, go back to the details page
                actions.push(
                  AutomationCommonActions.goToAutomationDetailsPage({
                    automationId: updatedAutomation.id,
                  }),
                );
              } else {
                actions.push(
                  AutomationCommonActions.navigateToSuccessReturnPath({ automation: updatedAutomation }),
                  AutomationCommonActions.refreshAutomations(),
                );
              }
              return actions;
            }),
            catchError((webError: WebError) => {
              const alertItems = (webError.errors || []).map((error: WebErrorMessage) => ({
                message: this.i18nService.translate(`WORKFLOWS.EDIT_${AUTOMATION_TYPE_KEY_MAP[activeAutomationType]}_ERROR_MSG`, {
                  reason: error.message,
                }),
                actionLink: error.actionLink,
                actionText: error.actionText ?? '',
              }));
              return [
                AutomationCommonActions.updateAutomationFailure(),
                AlertBannerActions.showAlertBanner({
                  alertType: ALERT_BANNER_TYPE.DANGER,
                  target: AlertBannerTarget.APP,
                  autoDismiss: false,
                  alertItems,
                }),
                AutomationCommonActions.closeAutomationDialog(),
              ];
            }),
          );
        },
      ),
    ),
  );

  /**
   * setSourceObjectBreadCrumbs
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public setSourceObjectBreadCrumbs$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.setSourceObjectBreadCrumbs),
      switchMap(({ sourceObjectId, sourceObjectType }: ReturnType<typeof AutomationCommonActions.setSourceObjectBreadCrumbs>) => {
        if (sourceObjectType === SourceObjectType.INCIDENT) {
          return [AutomationCommonActions.setIncidentAutomationBreadCrumbs({ incidentId: sourceObjectId })];
        }
        return [];
      }),
    ),
  );

  /**
   * setIncidentAutomationBreadCrumbs
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public setIncidentAutomationBreadCrumbs$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.setIncidentAutomationBreadCrumbs),
      switchMap(({ incidentId }: ReturnType<typeof AutomationCommonActions.setIncidentAutomationBreadCrumbs>) => {
        return this.automationService.getIncident(incidentId).pipe(
          map((automationIncident: DeemIncident) => {
            return NavigationActions.setBreadCrumbs({
              breadCrumbs: [
                new BreadCrumb({
                  pathUrl: `/${DEEM_ROUTE_NAMES.DEEM_HOME}`,
                  pathLabelKey: 'COMMON_MESSAGES.DEEM',
                }),
                new BreadCrumb({
                  pathUrl: `/${DEEM_ROUTE_NAMES.INCIDENT_ID(automationIncident.id)}`,
                  pathLabelKey: automationIncident.incidentKey,
                }),
              ],
            });
          }),
          catchError((error: WebError) => {
            return [
              NavigationActions.setBreadCrumbs({
                breadCrumbs: [],
              }),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.PAGE,
                message: this.i18nService.translate('INCIDENTS.GET_INCIDENTS_ERROR_MSG', {
                  reason: error.getFullReason(),
                }),
              }),
            ];
          }),
        );
      }),
    ),
  );

  /**
   * @type {Observable<Action>}
   * @memberof AutomationCommonEffects
   */
  public requestFieldLookup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationCommonActions.requestFieldLookup),
      withLatestFrom(
        this.store.select(AutomationCommonSelectors.getAllActionsByKey),
        this.store.select(ConnectorCommonSelectors.getConnectorsWithActions),
      ),
      switchMap(
        ([{ payload }, availableActionMethodsByKey, connectors]: [
          ReturnType<typeof AutomationCommonActions.requestFieldLookup>,
          Record<string, ConnectorAction>,
          Connector[],
        ]) => {
          const { fieldForLookup } = payload;
          const lookupActionMethod: ConnectorAction = availableActionMethodsByKey[fieldForLookup.lookupConfig.operationId];
          const actionDataBySection: Record<string, Array<Partial<NameValue>>> = reduce(
            lookupActionMethod.sections.QUERY_PARAMETER,
            (acc: Record<string, Array<Partial<NameValue>>>, section: ConnectorActionSection) => {
              const nameValueData = _map(section.metadata, (field) => {
                return getLookupFieldNameValue(field, payload, section.schema.properties[field.name]);
              });
              return {
                ...acc,
                [section.sectionType]: acc[section.sectionType] ? [...acc[section.sectionType], ...nameValueData] : nameValueData,
              };
            },
            {},
          );
          const connectorConfigId = connectors?.find((connector: Connector) => connector.id === lookupActionMethod.connectorId)?.configId;
          return this.automationService
            .getAutomationActionLookup(
              new AutomationActionLookupRequest({
                textExtractionExpression: fieldForLookup.lookupConfig.lookupText,
                valueExtractionExpression: fieldForLookup.lookupConfig.lookupValue,
                actionTemplateId: lookupActionMethod.id,
                connectorConfigId,
                dataBySection: actionDataBySection,
              }),
            )
            .pipe(
              // Timeout is needed here in order to fail the request after AppConfig.TIMEOUT_20S
              // if there is no data received in that timeframe, request will be handled via catchError
              timeout(AppConfig.TIMEOUT_20S),
              map((response: AutomationActionFieldLookupResponse) => {
                const lookupMap: Record<string, NameValue[]> = {};
                const actionTemplateId: string = fieldForLookup.lookupConfig.operationId
                  ? fieldForLookup.lookupConfig.operationId
                  : fieldForLookup.field.lookupConfig.operationId;
                lookupMap[`${fieldForLookup.field.name}_${actionTemplateId}`] = response.data.reduce(
                  (acc: NameValue[], curr: AutomationActionFieldLookupResponseData) => {
                    return [
                      ...acc,
                      {
                        name: curr.text,
                        value: curr.value,
                      },
                    ];
                  },
                  [],
                );
                return AutomationCommonActions.loadLookupValues({ lookupMap });
              }),
              catchError((error: WebError | TimeoutError) => [AutomationCommonActions.loadLookupValuesFailure({ error })]),
            );
        },
      ),
    ),
  );

  /**
   * goToCreateAutomationPage$
   * @type {Observable<[Action, string]>}
   * @memberof AutomationEffects
   */
  public goToCreateAutomationPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AutomationCommonActions.goToCreateAutomationPage),
        tap(({ automationType }: ReturnType<typeof AutomationCommonActions.goToCreateAutomationPage>) =>
          this.routerExtensions.navigate([`/${ROUTE_NAMES.GLOBAL_ORCHESTRATOR.ADD_WORKFLOW}`], {
            queryParams: automationType ? { [AppConfig.QUERY_PARAM_TYPE]: automationType } : {},
          }),
        ),
      ),
    { dispatch: false },
  );

  /**
   * Creates an instance of AutomationCommonEffects.
   * @param {Store<CoreAppState>} store
   * @param {Actions} actions$
   * @param {I18NService} i18nService
   * @param {AutomationService} automationService
   * @param {RouterExtensions} routerExtensions
   * @memberof AutomationCommonEffects
   */
  constructor(
    private store: Store<CoreAppState>,
    private actions$: Actions,
    private i18nService: I18NService,
    private automationService: AutomationService,
    private routerExtensions: RouterExtensions,
  ) {}
}
