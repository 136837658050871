/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { TemplateRef } from '@angular/core';

import { Column, DevicesDashboardConfig } from '@ws1c/intelligence-models';

/**
 * DeviceProfileLinkHelper
 * @export
 * @class DeviceProfileLinkHelper
 */
export class DeviceProfileLinkHelper {
  public static deviceNameFound: Column;
  public static deviceIdFound: Column;
  public static devicePlatformFound: Column;
  public static deviceModelFound: Column;
  public static deviceLinkMap: Record<string, Column>;

  /**
   * setCellTemplatesByColumnValue
   * @param {Column[]} newColumns
   * @param {Record<string, TemplateRef<any>>} cellTemplatesByColumnValue
   * @param {TemplateRef} deviceNameTemplate
   * @returns {[Record<string, TemplateRef<any>>, Record<string, Column>]}
   * @memberof DeviceProfileLinkHelper
   */
  public static setCellTemplatesByColumnValue(
    newColumns: Column[],
    cellTemplatesByColumnValue: Record<string, TemplateRef<any>>,
    deviceNameTemplate: TemplateRef<any>,
  ): [Record<string, TemplateRef<any>>, Record<string, Column>] {
    this.deviceNameFound = this.findDeviceKey(newColumns, DevicesDashboardConfig.deviceLinkKeys.names);
    this.deviceIdFound = this.findDeviceKey(newColumns, DevicesDashboardConfig.deviceLinkKeys.ids);
    this.devicePlatformFound = this.findDeviceKey(newColumns, DevicesDashboardConfig.deviceLinkKeys.platforms);
    this.deviceModelFound = this.findDeviceKey(newColumns, DevicesDashboardConfig.deviceLinkKeys.models);
    this.deviceLinkMap = {
      name: this.deviceNameFound,
      id: this.deviceIdFound,
      model: this.deviceModelFound,
      platform: this.devicePlatformFound,
    };
    if (this.deviceNameFound?.name && this.deviceIdFound?.name) {
      cellTemplatesByColumnValue = {
        ...cellTemplatesByColumnValue,
        [this.deviceNameFound.attributeName]: deviceNameTemplate,
      };
    }
    if (!this.deviceNameFound?.name && this.deviceIdFound?.name) {
      cellTemplatesByColumnValue = {
        ...cellTemplatesByColumnValue,
        [this.deviceIdFound.attributeName]: deviceNameTemplate,
      };
    }
    return [cellTemplatesByColumnValue, this.deviceLinkMap];
  }

  /**
   * findDeviceKey
   * @param {Column[]} cols
   * @param {string[]} matches
   * @returns {Column}
   * @memberof DeviceProfileLinkHelper
   */
  public static findDeviceKey(cols: Column[], matches: string[]): Column {
    return cols.find((item: Column) => matches.includes(item.name));
  }
}
