/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, filter, Observable, Subscription, switchMap, take, withLatestFrom } from 'rxjs';

import { I18NService } from '@ws1c/intelligence-common';
import { SidepanelTemplateId } from '@ws1c/intelligence-core/const';
import { GoSidepanelService } from '@ws1c/intelligence-core/services';
import { AutomationCommonActions, AutomationCommonSelectors } from '@ws1c/intelligence-core/store/automation-common';
import { UserPreferenceFeatureControlsSelectors } from '@ws1c/intelligence-core/store/user-preference/user-preference-feature-controls.selectors';
import {
  Automation,
  AUTOMATION_TYPE_KEY_MAP,
  AutomationDialogMode,
  AutomationType,
  CLARITY_TOOLTIP_POSITION,
  CustomReportPreviewSearchResponse,
  EvaluationType,
} from '@ws1c/intelligence-models';

/**
 * Automation Modals
 * @export
 * @class AutomationModalsComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-automation-modals',
  templateUrl: 'automation-modals.component.html',
  styleUrls: ['automation-modals.component.scss'],
})
export class AutomationModalsComponent implements OnInit, OnDestroy {
  @Input() public fromDetailView?: boolean = false;

  public copyAutomationDialogOpen$: Observable<boolean>;
  public renameAutomationDialogOpen$: Observable<boolean>;
  public deleteAutomationDialogOpen$: Observable<boolean>;
  public runAutomationDialogOpen$: Observable<boolean>;
  public toggleAutomationStatusDialogOpen$: Observable<boolean>;
  public showSaveAndEnableModal$: Observable<boolean>;
  public isPreviewDataLoading$: Observable<boolean>;
  public previewData$: Observable<CustomReportPreviewSearchResponse>;
  public arePotentialTargetsAboveLimit$: Observable<boolean>;
  public showSaveDraftModal$: Observable<boolean>;
  public showEditEnabledAutomationModal$: Observable<boolean>;
  public isAutomaticEvaluationType$: Observable<boolean>;
  public isNewTriggersEnabled$: Observable<boolean>;
  public isBatchModeEnabled$: Observable<boolean>;
  public isInactiveInBatchMode$: Observable<boolean>;
  public irrecoverableActions$: Observable<any[]>;
  public isLoadingAutomationRequest$: Observable<boolean>;
  public isOnlySupportsHistoricalData$: Observable<boolean>;
  public activeAutomationType$: Observable<AutomationType>;
  public automationNameForm: UntypedFormGroup;
  public isActive: boolean;
  public automationName: string;
  public automationDialogModel: Automation;
  public countAutomationIrrecoverableActions: number = 0;
  public oneTimeManualRunControl: FormControl;
  public evaluatePotentialImpact: boolean = false;
  public confirmSave: boolean = false;
  public draftFormGroup: UntypedFormGroup;

  public readonly AUTOMATION_TYPE_KEY_MAP = AUTOMATION_TYPE_KEY_MAP;
  public readonly CLARITY_TOOLTIP_POSITION = CLARITY_TOOLTIP_POSITION;
  public readonly AutomationType = AutomationType;

  private subscription: Subscription = new Subscription();

  /**
   * Creates an instance of AutomationModalsComponent.
   * @param {Store} store
   * @param {UntypedFormBuilder} fb
   * @param {I18NService} i18nService
   * @param {GoSidepanelService} sidepanelService
   * @memberof AutomationModalsComponent
   */
  constructor(
    private store: Store,
    private fb: UntypedFormBuilder,
    private i18nService: I18NService,
    private sidepanelService: GoSidepanelService,
  ) {
    this.copyAutomationDialogOpen$ = this.store.select(AutomationCommonSelectors.isAutomationDialogMode(AutomationDialogMode.COPY));
    this.renameAutomationDialogOpen$ = this.store.select(AutomationCommonSelectors.isAutomationDialogMode(AutomationDialogMode.RENAME));
    this.deleteAutomationDialogOpen$ = this.store.select(AutomationCommonSelectors.isAutomationDialogMode(AutomationDialogMode.DELETE));
    this.toggleAutomationStatusDialogOpen$ = this.store.select(
      AutomationCommonSelectors.isAutomationDialogMode(AutomationDialogMode.TOGGLE),
    );
    this.runAutomationDialogOpen$ = this.store.select(AutomationCommonSelectors.isAutomationDialogMode(AutomationDialogMode.RUN));
    this.showSaveAndEnableModal$ = this.store.select(
      AutomationCommonSelectors.isAutomationDialogMode(AutomationDialogMode.SAVE_AND_ENABLE),
    );
    this.isPreviewDataLoading$ = this.store.select(AutomationCommonSelectors.getAutomationPreviewDataLoading);
    this.previewData$ = this.store.select(AutomationCommonSelectors.getAutomationPreviewData);
    this.arePotentialTargetsAboveLimit$ = this.store.select(AutomationCommonSelectors.arePotentialImpactTargetsAboveLimit);
    this.showSaveDraftModal$ = this.store.select(AutomationCommonSelectors.isAutomationDialogMode(AutomationDialogMode.SAVE_DRAFT));
    this.showEditEnabledAutomationModal$ = this.store.select(
      AutomationCommonSelectors.isAutomationDialogMode(AutomationDialogMode.EDIT_CONFIRMATION),
    );
    this.isBatchModeEnabled$ = this.store.select(AutomationCommonSelectors.isAutomationReadyForBatchMode);
    this.isInactiveInBatchMode$ = this.store.select(AutomationCommonSelectors.isInactiveAutomationInBatchMode);
    this.isAutomaticEvaluationType$ = this.store.select(
      AutomationCommonSelectors.isSelectedAutomationEvaluationType(EvaluationType.AUTOMATIC),
    );
    this.isNewTriggersEnabled$ = this.store.select(UserPreferenceFeatureControlsSelectors.isAutomationEvaluationTriggersEnabled);
    this.isLoadingAutomationRequest$ = this.store.select(AutomationCommonSelectors.isLoadingAutomationRequest);
    this.isOnlySupportsHistoricalData$ = this.store.select(AutomationCommonSelectors.isAutomationCategoryOnlySupportsHistoricalData);
    this.activeAutomationType$ = this.store.select(AutomationCommonSelectors.getActiveAutomationType);

    this.automationNameForm = this.fb.group({
      name: ['', Validators.required],
    });

    this.draftFormGroup = this.fb.group({
      name: ['', Validators.required],
      description: [''],
    });

    this.oneTimeManualRunControl = new FormControl(false);
  }

  /**
   * ngOnInit
   * @memberof AutomationModalsComponent
   */
  public ngOnInit() {
    this.onInitSaveAndEnable();
    this.subscription.add(
      this.store.select(AutomationCommonSelectors.getAutomationIrrecoverableConnectorActions).subscribe((irrecoverableActions: any[]) => {
        this.countAutomationIrrecoverableActions = irrecoverableActions ? irrecoverableActions.length : 0;
      }),
    );
    this.subscription.add(
      combineLatest([
        this.store.select(AutomationCommonSelectors.getAutomationDialogMode),
        this.store.select(AutomationCommonSelectors.getAutomationDialogModel),
      ]).subscribe(([dialogMode, automationDialogModel]: [AutomationDialogMode, Automation]) => {
        if (!automationDialogModel) {
          return;
        }

        this.automationDialogModel = automationDialogModel;
        this.automationName = automationDialogModel.name;

        switch (dialogMode) {
          case AutomationDialogMode.COPY:
            this.automationNameForm.patchValue({
              name: this.i18nService.translate('COMMON_MESSAGES.COPY_OF', { name: automationDialogModel.name || '' }),
            });
            break;
          case AutomationDialogMode.RENAME:
            this.automationNameForm.patchValue({
              name: automationDialogModel.name,
            });
            break;
          case AutomationDialogMode.TOGGLE:
            this.isActive = automationDialogModel.active;
            break;
        }
      }),
    );
  }

  /**
   * onInitSaveAndEnable
   * @memberof AutomationModalsComponent
   */
  public onInitSaveAndEnable() {
    this.subscription.add(
      this.store
        .select(AutomationCommonSelectors.isAutomationDialogMode(AutomationDialogMode.SAVE_AND_ENABLE))
        .pipe(
          filter(Boolean),
          withLatestFrom(
            this.store.select(AutomationCommonSelectors.getActiveAutomationType),
            this.store.select(AutomationCommonSelectors.isSelectedAutomationEvaluationType(EvaluationType.AUTOMATIC)),
          ),
        )
        .subscribe(([_showShowSaveAndEnableModal, automationType, isAutomaticEvaluationType]: [boolean, AutomationType, boolean]) => {
          // Reset state when the Save & Enable modal opens
          this.evaluatePotentialImpact = false;
          this.confirmSave = false;
          // Evaluate potential impact for non-automatic trigger types right away
          if (automationType === AutomationType.GENERAL && !isAutomaticEvaluationType) {
            this.store.dispatch(AutomationCommonActions.refreshAutomationPreviewData());
            this.evaluatePotentialImpact = true;
          }
        }),
    );
    this.subscription.add(
      this.store
        .select(AutomationCommonSelectors.isAutomationDialogMode(AutomationDialogMode.SAVE_AND_ENABLE))
        .pipe(
          filter(Boolean),
          switchMap(() =>
            // Start observing valueChanges anew each time modal opens
            this.oneTimeManualRunControl.valueChanges.pipe(
              withLatestFrom(this.store.select(AutomationCommonSelectors.isSelectedAutomationEvaluationType(EvaluationType.AUTOMATIC))),
              filter(([oneTimeManualRun, isAutomaticEvaluationType]) => oneTimeManualRun && isAutomaticEvaluationType),
              take(1), // Dispatch action only first time toggle turned on for automation trigger type
            ),
          ),
        )
        .subscribe(() => {
          this.store.dispatch(AutomationCommonActions.refreshAutomationPreviewData());
          this.confirmSave = false;
        }),
    );
    this.subscription.add(
      this.oneTimeManualRunControl.valueChanges
        .pipe(
          withLatestFrom(
            this.store.select(AutomationCommonSelectors.isAutomationDialogMode(AutomationDialogMode.SAVE_AND_ENABLE)),
            this.store.select(AutomationCommonSelectors.isSelectedAutomationEvaluationType(EvaluationType.AUTOMATIC)),
          ),
          filter(
            ([_oneTimeManualRun, showSaveAndEnableModal, isAutomationEvaluationType]: [boolean, boolean, boolean]) =>
              showSaveAndEnableModal && isAutomationEvaluationType,
          ),
        )
        .subscribe(([oneTimeManualRun]: [boolean, boolean, boolean]) => {
          // Update state when toggle changes for automatic trigger type
          this.evaluatePotentialImpact = oneTimeManualRun;
        }),
    );
  }

  /**
   * confirmCopyAutomation
   *
   * @param {string} newAutomationName
   * @memberof AutomationModalsComponent
   */
  public confirmCopyAutomation(newAutomationName: string) {
    this.store.dispatch(
      AutomationCommonActions.confirmCopyAutomation({
        newAutomationName,
        fromDetailView: this.fromDetailView,
      }),
    );
  }

  /**
   * @param {string} newAutomationName
   * @memberof AutomationModalsComponent
   */
  public confirmRenameAutomation(newAutomationName: string) {
    this.store.dispatch(
      AutomationCommonActions.confirmRenameAutomation({
        newAutomationName,
        fromDetailView: this.fromDetailView,
      }),
    );
  }

  /**
   * confirmDeleteAutomation
   * @memberof AutomationModalsComponent
   */
  public confirmDeleteAutomation() {
    this.store.dispatch(
      AutomationCommonActions.confirmDeleteAutomation({
        fromDetailView: this.fromDetailView,
      }),
    );
  }

  /**
   * confirmRunAutomation
   * @memberof AutomationModalsComponent
   */
  public confirmRunAutomation() {
    this.store.dispatch(
      AutomationCommonActions.confirmRunAutomation({
        automation: this.automationDialogModel,
      }),
    );
  }

  /**
   * closeModal
   * @memberof AutomationModalsComponent
   */
  public closeModal() {
    this.store.dispatch(AutomationCommonActions.closeAutomationDialog());
    this.oneTimeManualRunControl.setValue(false, { emitEvent: false });
  }

  /**
   * nameWarningLabelVisible
   * @returns {boolean}
   * @memberof AutomationModalsComponent
   */
  public nameWarningLabelVisible(): boolean {
    const nameFormControl = this.automationNameForm.get('name');
    return nameFormControl.invalid && (nameFormControl.dirty || nameFormControl.touched);
  }

  /**
   * ngOnDestroy
   * @memberof AutomationModalsComponent
   */
  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * toggleAutomation
   * @memberof AutomationModalsComponent
   */
  public toggleAutomation() {
    this.store.dispatch(
      AutomationCommonActions.changeAutomationStatus({
        fromDetailView: this.fromDetailView,
        isOneTimeManualRunEnabled: this.oneTimeManualRunControl.value,
      }),
    );
  }

  /**
   * saveAndEnableAutomation
   * @memberof AutomationModalsComponent
   */
  public saveAndEnableAutomation() {
    this.store.dispatch(
      AutomationCommonActions.automationSaveAndEnableConfirm({
        isOneTimeManualRunEnabled: this.oneTimeManualRunControl.value,
        fromDetailView: this.fromDetailView,
      }),
    );
  }

  /**
   * saveDraft
   * @memberof AutomationModalsComponent
   */
  public saveDraft() {
    this.store.dispatch(
      AutomationCommonActions.updateWizardModel({
        automation: {
          name: this.draftFormGroup.get('name').value,
          description: this.draftFormGroup.get('description').value,
        },
      }),
    );
    this.store.dispatch(AutomationCommonActions.confirmCreateAutomation({}));
  }

  /**
   * openTriggerRules
   * @memberof AutomationModalsComponent
   */
  public openTriggerRules() {
    this.store.dispatch(AutomationCommonActions.setShowTriggerRules({ showTriggerRules: true }));
    this.closeModal();
  }

  /**
   * openPotentialImpactSidepanel
   * @memberof AutomationModalsComponent
   */
  public openPotentialImpactSidepanel() {
    this.sidepanelService.show({
      template: SidepanelTemplateId.POTENTIAL_IMPACT,
      sidepanelClass: 'sidepanel--large',
    });
    this.closeModal();
  }

  /**
   * togglePrimaryButtonLabel
   * @type {string}
   * @memberof AutomationModalsComponent
   */
  public get togglePrimaryButtonLabel(): string {
    if (this.isActive) {
      return 'AUTOMATION_ACTIONS.DISABLE';
    }
    if (this.oneTimeManualRunControl.value) {
      return 'AUTOMATION_ACTIONS.ENABLE_AND_RUN';
    }
    return 'AUTOMATION_ACTIONS.ENABLE';
  }

  /**
   * requiredWarningVisible
   * @param {string} fieldName
   * @returns {boolean}
   * @memberof AutomationModalsComponent
   */
  public requiredWarningVisible(fieldName: string): boolean {
    const formControl = this.draftFormGroup.get(fieldName);
    return formControl.invalid && (formControl.dirty || formControl.touched);
  }

  /**
   * goToEditAutomationPage
   * @param {string} automationId
   * @param {AutomationType} automationType
   * @memberof AutomationModalsComponent
   */
  public goToEditAutomationPage(automationId: string, automationType: AutomationType) {
    this.store.dispatch(
      AutomationCommonActions.goToEditAutomationPage({
        automationId,
        automationType,
      }),
    );
  }
}
