/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { SortOn } from '@dpa/ui-common';
import { orderBy, sortedUniqBy } from 'lodash-es';

import { UserProfileConfig } from '@ws1c/intelligence-common';
import {
  AppDetailTabType,
  AppPlatform,
  COLUMN_NAMES,
  CustomReportPreviewSearchResponse,
  DashboardConfig,
  Device,
  DeviceUser,
  Entity,
  FilterRule,
  Integration,
  PreviewReportContentRequest,
  RuleGroup,
  StandardDashboardRequest,
  StandardDashboardType,
  TrendDateRange,
  UserDetailPageType,
} from '@ws1c/intelligence-models';
import { UserProfileAdditionalParams } from './user-profile.state';

/**
 * Export helpers to support spy on functions
 */
export const helpers = {
  buildStandardAppUsersDashboardRequest,
  buildStandardUserProfileDashboardRequest,
  getDeviceDetailRequest,
  getUserProfileDesktopDeviceRequest,
  getUserProfileMobileDeviceRequest,
  getUserProfileApps,
  getUserProfileDesktopApps,
  getUserProfileMobileApps,
  getUserProfileExperienceMultiRequest,
  getUserProfileExperienceAppMultiRequest,
  getUserProfileDetailRequest,
  getLatestFromResponse,
};

/**
 * buildStandardAppUsersDashboardRequest
 * @param {string} appId
 * @param {string} appName
 * @param {string} apteligentId
 * @param {string} appPlatform
 * @param {RuleGroup} appDashboardFilterRuleGroup
 * @param {TrendDateRange} appDashboardTrendDateRange
 * @returns {StandardDashboardRequest}
 * @export
 */
export function buildStandardAppUsersDashboardRequest(
  appId: string,
  appName: string,
  apteligentId: string,
  appPlatform: string,
  appDashboardFilterRuleGroup: RuleGroup,
  appDashboardTrendDateRange: TrendDateRange,
): StandardDashboardRequest {
  const filterRules = appDashboardFilterRuleGroup?.rules ?? [];
  let appFieldName = COLUMN_NAMES.byName.app_package_id;
  let appFieldValue = appId;
  if (appPlatform === AppPlatform.WINDOWS_DESKTOP) {
    appFieldName = COLUMN_NAMES.byName.app_name;
    appFieldValue = appName;
  }
  return new StandardDashboardRequest(StandardDashboardType.USERS, new RuleGroup(filterRules), appDashboardTrendDateRange, {
    app_field_name: appFieldName,
    app_field_value: appFieldValue,
    _device_platform: appPlatform,
    apteligent_id: apteligentId,
  });
}

/**
 * buildStandardUserProfileDashboardRequest
 * @param {string} appId
 * @param {string} appName
 * @param {string} apteligentId
 * @param {string} appPlatform
 * @param {RuleGroup} appDashboardFilterRuleGroup
 * @param {TrendDateRange} appDashboardTrendDateRange
 * @param {string} appUser
 * @param {string} deviceModel
 * @returns {StandardDashboardRequest}
 * @export
 */
export function buildStandardUserProfileDashboardRequest(
  appId: string,
  appName: string,
  apteligentId: string,
  appPlatform: string,
  appDashboardFilterRuleGroup: RuleGroup,
  appDashboardTrendDateRange: TrendDateRange,
  appUser: string,
  deviceModel: string,
): StandardDashboardRequest {
  const filterRules =
    appDashboardFilterRuleGroup?.rules.filter((rule: FilterRule) => {
      return rule.attribute === COLUMN_NAMES.byName._app_version;
    }) ?? [];
  if (deviceModel !== AppDetailTabType.OVERVIEW) {
    filterRules.push(
      new FilterRule({
        attribute:
          appPlatform === AppPlatform.ANDROID
            ? COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_android_device_friendly_name
            : COLUMN_NAMES.byFullyQualifiedName.apteligent_grouped_crash_ios_device_friendly_name,
        condition: FilterRule.FILTER_CONDITION.includes,
        data: deviceModel,
      }),
    );
  }
  let appFieldName = COLUMN_NAMES.byName.app_package_id;
  let appFieldValue = appId;
  if (appPlatform === AppPlatform.WINDOWS_DESKTOP) {
    appFieldName = COLUMN_NAMES.byName.app_name;
    appFieldValue = appName;
  }
  const requestStandardDashboardType =
    appPlatform === AppPlatform.ANDROID ? StandardDashboardType.USER_PROFILE_ANDROID : StandardDashboardType.USER_PROFILE_IOS;
  return new StandardDashboardRequest(requestStandardDashboardType, new RuleGroup(filterRules), appDashboardTrendDateRange, {
    app_field_name: appFieldName,
    app_field_value: appFieldValue,
    apteligent_id: apteligentId,
    user_name: appUser,
  });
}

/**
 * getDeviceDetailRequest
 * @param {Device} device
 * @returns {PreviewReportContentRequest}
 */
export function getDeviceDetailRequest(device: Device): PreviewReportContentRequest {
  return new PreviewReportContentRequest({
    filter: `${COLUMN_NAMES.byFullyQualifiedName.airwatch_device_airwatch_device_guid} = '${device.id}'`,
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
    ],
    entitiesByIntegration: {
      [Integration.AIRWATCH]: [Entity.DEVICE],
    },
    offset: 0,
    pageSize: 1,
    sortOns: [
      new SortOn({
        by: COLUMN_NAMES.byFullyQualifiedName.airwatch_device_adp_modified_at,
        reverse: true,
      }),
    ],
  });
}

/**
 * getUserProfileExperienceMultiRequest
 * @param {PreviewReportContentRequest[]} requests
 * @param {UserProfileAdditionalParams} additionalParams
 * @param {UserDetailPageType} userDetailPageType
 * @returns {PreviewReportContentRequest[]}
 */
export function getUserProfileExperienceMultiRequest(
  requests: PreviewReportContentRequest[],
  additionalParams: UserProfileAdditionalParams,
  userDetailPageType: UserDetailPageType,
): PreviewReportContentRequest[] {
  // overrides score lookup within 1 day / 4 hour
  const trendDateRange = UserProfileConfig.UserDetailPageConfig[userDetailPageType]?.trendDateRange;
  return [
    helpers.getUserProfileDesktopDeviceRequest(requests?.[0], additionalParams, trendDateRange),
    helpers.getUserProfileApps(requests?.[1], additionalParams, trendDateRange),
  ];
}

/**
 * getUserProfileExperienceAppMultiRequest
 * @param {PreviewReportContentRequest[]} requests
 * @param {CustomReportPreviewSearchResponse} response
 * @param {UserDetailPageType} userDetailPageType
 * @returns {PreviewReportContentRequest[]}
 */
export function getUserProfileExperienceAppMultiRequest(
  requests: PreviewReportContentRequest[],
  response: CustomReportPreviewSearchResponse,
  userDetailPageType: UserDetailPageType,
): PreviewReportContentRequest[] {
  // overrides score lookup within 1 day / 4 hours
  const trendDateRange = UserProfileConfig.UserDetailPageConfig[userDetailPageType].trendDateRange;
  return [
    helpers.getUserProfileDesktopApps(trendDateRange, requests?.[0], response),
    helpers.getUserProfileMobileApps(trendDateRange, requests?.[1], response),
  ];
}

/**
 * getUserProfileMobileDeviceRequest
 * @param {PreviewReportContentRequest} request
 * @param {UserProfileAdditionalParams} additionalParams
 * @returns {PreviewReportContentRequest}
 */
export function getUserProfileMobileDeviceRequest(
  request: PreviewReportContentRequest,
  additionalParams: UserProfileAdditionalParams,
): PreviewReportContentRequest {
  if (request || !additionalParams?.user_guid) {
    return request;
  }
  const filter = `${COLUMN_NAMES.byFullyQualifiedName.airwatch_device_user_guid} = '${additionalParams.user_guid}' AND ${
    COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform
  } IN ( '${DashboardConfig.MOBILE_OS.join("','")}' )`;
  return new PreviewReportContentRequest({
    entity: Entity.DEVICE,
    entitiesByIntegration: {
      [Integration.AIRWATCH]: [Entity.DEVICE],
    },
    filter,
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_friendly_name,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_airwatch_device_guid,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_last_seen,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_platform,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_model,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_device_device_os_version,
    ],
    pageSize: 25,
    offset: 0,
    sortOns: [],
  });
}

/**
 * getUserProfileDesktopDeviceRequest
 * @param {PreviewReportContentRequest} request
 * @param {UserProfileAdditionalParams} additionalParams
 * @param {TrendDateRange} trendDateRange
 * @returns {PreviewReportContentRequest}
 */
export function getUserProfileDesktopDeviceRequest(
  request: PreviewReportContentRequest,
  additionalParams: UserProfileAdditionalParams,
  trendDateRange: TrendDateRange,
): PreviewReportContentRequest {
  if (request || !additionalParams?.user_guid) {
    return request;
  }
  const startEndMillis = trendDateRange.getStartEndMillis();
  const filter = `${COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_user_guid} = '${additionalParams.user_guid}'`;
  const sortOns = [
    new SortOn({
      by: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_overall_score,
      reverse: true,
    }),
  ];
  return new PreviewReportContentRequest({
    entitiesByIntegration: {
      [Integration.INTELLIGENCE]: [Entity.EXPERIENCESCORE_DEVICE],
    },
    startDateMillis: startEndMillis.startDateMillis,
    endDateMillis: startEndMillis.endDateMillis,
    filter,
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_name,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_airwatch_device_guid,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_score_calculation_timestamp,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_platform,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_model,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_os_version,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_device_overall_score,
    ],
    pageSize: 25,
    offset: 0,
    sortOns,
  });
}

/**
 * getUserProfileApps
 * @param {PreviewReportContentRequest} request
 * @param {UserProfileAdditionalParams} additionalParams
 * @param {TrendDateRange} trendDateRange
 * @returns {PreviewReportContentRequest}
 */
export function getUserProfileApps(
  request: PreviewReportContentRequest,
  additionalParams: UserProfileAdditionalParams,
  trendDateRange: TrendDateRange,
): PreviewReportContentRequest {
  if (request || !additionalParams?.user_guid) {
    return request;
  }
  const startEndMillis = trendDateRange.getStartEndMillis();
  const filter = `${COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_user_user_guid} = '${additionalParams.user_guid}'`;
  const sortOns = [
    new SortOn({
      by: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_user_user_overall_score,
      reverse: true,
    }),
  ];
  return new PreviewReportContentRequest({
    entitiesByIntegration: {
      [Integration.INTELLIGENCE]: [Entity.EXPERIENCESCORE_USER],
    },
    startDateMillis: startEndMillis.startDateMillis,
    endDateMillis: startEndMillis.endDateMillis,
    filter,
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_user_user_top_desktop_apps,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_user_user_productivity_apps,
    ],
    pageSize: 10,
    offset: 0,
    sortOns,
  });
}

/**
 * getUserProfileDesktopApps
 * @param {TrendDateRange} trendDateRange
 * @param {PreviewReportContentRequest} request
 * @param {CustomReportPreviewSearchResponse} response
 * @returns {PreviewReportContentRequest}
 */
export function getUserProfileDesktopApps(
  trendDateRange: TrendDateRange,
  request: PreviewReportContentRequest,
  response: CustomReportPreviewSearchResponse,
): PreviewReportContentRequest {
  if (request) {
    return request;
  }
  const startEndMillis = trendDateRange.getStartEndMillis();
  const appNames =
    response.results[0]?.[`${COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_user_user_top_desktop_apps}`]?.join(`','`) || '';
  const filter = `${COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_app_name_version_platform} IN ('${appNames}')`;
  const sortOns = [
    new SortOn({
      by: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_desktop_app_overall_score,
      reverse: true,
    }),
  ];
  return new PreviewReportContentRequest({
    entitiesByIntegration: {
      [Integration.INTELLIGENCE]: [Entity.EXPERIENCESCORE_DESKTOP_APP],
    },
    startDateMillis: startEndMillis.startDateMillis,
    endDateMillis: startEndMillis.endDateMillis,
    filter,
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_app_name,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_app_name_version_platform,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_score_calculation_timestamp,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_device_platform,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_app_version,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_app_crash_count,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_app_hang_rate,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_desktop_app_overall_score,
    ],
    pageSize: 100,
    offset: 0,
    sortOns,
  });
}

/**
 * getUserProfileMobileApps
 * @param {TrendDateRange} trendDateRange
 * @param {PreviewReportContentRequest} request
 * @param {CustomReportPreviewSearchResponse} response
 * @returns {PreviewReportContentRequest}
 */
export function getUserProfileMobileApps(
  trendDateRange: TrendDateRange,
  request: PreviewReportContentRequest,
  response: CustomReportPreviewSearchResponse,
): PreviewReportContentRequest {
  if (request) {
    return request;
  }
  const startEndMillis = trendDateRange.getStartEndMillis();
  const appNames =
    response.results[0]?.[`${COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_user_user_productivity_apps}`]?.join(`','`) || '';
  const filter = `${COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_id_version} IN ('${appNames}')`;
  const sortOns = [
    new SortOn({
      by: COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_mobile_app_overall_score,
      reverse: true,
    }),
  ];
  return new PreviewReportContentRequest({
    entitiesByIntegration: {
      [Integration.INTELLIGENCE]: [Entity.EXPERIENCESCORE_MOBILE_APP],
    },
    startDateMillis: startEndMillis.startDateMillis,
    endDateMillis: startEndMillis.endDateMillis,
    filter,
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_package_id,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_id_version,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_score_calculation_timestamp,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_device_platform,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_version,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_crash_count,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_handled_exceptions,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_net_error_rate,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_avg_app_latency_millis,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_mobile_app_overall_score,
      COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_id,
    ],
    pageSize: 100,
    offset: 0,
    sortOns,
  });
}

/**
 * getUserProfileDetailRequest
 * @param {DeviceUser} user
 * @returns {PreviewReportContentRequest}
 */
export function getUserProfileDetailRequest(user: DeviceUser): PreviewReportContentRequest {
  return new PreviewReportContentRequest({
    entity: Entity.USER,
    entitiesByIntegration: {
      [Integration.AIRWATCH]: [Entity.USER],
    },
    filter: `${COLUMN_NAMES.byFullyQualifiedName.airwatch_user_user_guid} = '${user.id}'`,
    fields: [
      COLUMN_NAMES.byFullyQualifiedName.airwatch_user_device_enrollment_user_name,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_user_device_enrollment_user_first_name,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_user_device_enrollment_user_last_name,
      COLUMN_NAMES.byFullyQualifiedName.airwatch_user_adp_modified_at,
    ],
    offset: 0,
    pageSize: 1,
    sortOns: [],
  });
}

/**
 * getLatestFromResponse
 * @param {CustomReportPreviewSearchResponse} resp
 * @param {string} id
 * @param {string} latestBy
 * @returns {CustomReportPreviewSearchResponse}
 * @memberof UserProfileExperienceComponent
 */
export function getLatestFromResponse(
  resp: CustomReportPreviewSearchResponse,
  id: string,
  latestBy: string,
): CustomReportPreviewSearchResponse {
  if (!resp) {
    return resp;
  }

  const sorted = orderBy(resp.results, [id, latestBy], ['asc', 'desc']);
  const results = sortedUniqBy(sorted, (item) => item[id]);
  return new CustomReportPreviewSearchResponse({
    ...resp,
    results,
    total: results.length,
  });
}
