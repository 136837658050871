/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { deserialize, GenericObject, WebError } from '@dpa/ui-common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { each, isEmpty, keyBy } from 'lodash-es';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { I18NService } from '@ws1c/intelligence-common';
import { IntegrationMetaService } from '@ws1c/intelligence-core/services';
import {
  AlertBannerActions,
  IntegrationMetaActions,
  IntegrationMetaSelectors,
  RbacSelectors,
  UserPreferenceActions,
  UserPreferenceFeatureControlsSelectors,
  UserPreferenceSelectors,
} from '@ws1c/intelligence-core/store';
import {
  AccountRole,
  AggregationAttributesResponse,
  ALERT_BANNER_TYPE,
  AlertBannerTarget,
  Category,
  CategoryColumns,
  CategoryIndex,
  Column,
  CustomAttributeIdentifiersAndColumnsResponse,
  CustomAttributeIdentifiersSearchResponse,
  FilterAttributeConfig,
  getUniqueId,
  PrecomputedAggregation,
  SuggestionCriteria,
  SuggestionFilterBy,
  SuggestionSearch,
  SuggestionSearchItem,
  SuggestionSearchResponse,
  Tag,
  TAGS_TYPE,
} from '@ws1c/intelligence-models';

/**
 * IntegrationMetaEffects
 * @export
 * @class IntegrationMetaEffects
 */
@Injectable()
export class IntegrationMetaEffects {
  /**
   * loadColumns$
   * @type {Observable<Action>}
   * @memberof IntegrationMetaEffects
   */
  public loadColumns$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationMetaActions.loadColumns, IntegrationMetaActions.loadColumnsQuietly),
      withLatestFrom(
        this.store.select(IntegrationMetaSelectors.getColumnsByCategory),
        this.store.select(IntegrationMetaSelectors.getCrossCategoryColumns),
        this.store.select(IntegrationMetaSelectors.getCategoriesByCategoryId),
        (
          { categoryId, isCrossCategory = false }: ReturnType<typeof IntegrationMetaActions.loadColumns>,
          columnsByCategory: Map<Category, Column[]>,
          crossCategoryColumnsByCategory: Record<string, Column[]>,
          categoriesByCategoryId: CategoryIndex,
        ) => {
          const category = categoriesByCategoryId[categoryId];
          const columns = isCrossCategory ? crossCategoryColumnsByCategory[categoryId] : columnsByCategory.get(category);
          return {
            isCrossCategory,
            category: columns ? undefined : categoriesByCategoryId[categoryId],
          };
        },
      ),
      filter(({ category }: { isCrossCategory: boolean; category: Category }) => !!category),
      mergeMap(({ isCrossCategory, category }: { isCrossCategory: boolean; category: Category }) => {
        return this.integrationMetaService.getAttributesForIntegrationAndEntityV2(category, isCrossCategory).pipe(
          map((response: Column[]) =>
            IntegrationMetaActions.loadColumnsSuccess({
              category,
              columns: response,
            }),
          ),
        );
      }),
    ),
  );

  /**
   * loadColumnsForMultiCategory
   * @type {Observable<Action>}
   * @memberof IntegrationMetaEffects
   */
  public loadColumnsForMultiCategory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationMetaActions.loadColumnsForMultiCategory),
      // get columnsByCategory from store and remove the entities for which entries already exist
      withLatestFrom(
        this.store.select(IntegrationMetaSelectors.getColumnsByCategory),
        (props: { entitiesByIntegration: GenericObject; activeCategories: Category[] }, columnByCategory: Map<Category, Column[]>) => {
          const filteredEntitiesByIntegration = {};
          const activeCategoriesById = keyBy(props.activeCategories, (category: Category) => category.categoryId);
          each(props.entitiesByIntegration, (entities: string[], integrationName: string) => {
            const validEntities = entities.filter(
              (entity: string) => !columnByCategory.has(activeCategoriesById[getUniqueId(integrationName, entity)]),
            );
            if (!isEmpty(validEntities)) {
              filteredEntitiesByIntegration[integrationName] = validEntities;
            }
          });
          return filteredEntitiesByIntegration;
        },
      ),
      filter((entitiesByIntegration: GenericObject) => !isEmpty(entitiesByIntegration)),
      switchMap((entitiesByIntegration: GenericObject) => {
        return this.integrationMetaService.getAttributesForEntitiesByIntegration(entitiesByIntegration).pipe(
          map((categoryColumns: CategoryColumns) => {
            return IntegrationMetaActions.loadColumnsForMultiCategorySuccess({ categoryColumns });
          }),
        );
      }),
    ),
  );

  /**
   * searchFilterValues
   * @type {Observable<Action>}
   * @memberof IntegrationMetaEffects
   */
  public searchFilterValues$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationMetaActions.searchFilterValues),
      debounceTime(250),
      switchMap(({ suggestionSearch }: { suggestionSearch: SuggestionSearch }) => {
        return this.integrationMetaService.getFilterSuggestionsV2(suggestionSearch.suggestionCriteria).pipe(
          map((suggestionSearchResponse: SuggestionSearchResponse) => {
            return IntegrationMetaActions.searchFilterValuesSuccess({
              suggestionValues: suggestionSearchResponse.values,
              suggestionSearch,
            });
          }),
          catchError((error: WebError) => [
            IntegrationMetaActions.searchFilterValuesFailure({ suggestionSearch }),
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.MODAL,
              message: this.i18nService.translate('COMMON_MESSAGES.SUGGESTIONS_ERROR_MSG', {
                reason: error.getFullReason(),
              }),
            }),
          ]),
        );
      }),
    ),
  );

  /**
   * getAvailableFilterTags
   * @type {Observable<Action>}
   * @memberof IntegrationMetaEffects
   */
  public getAvailableFilterTags$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationMetaActions.getAvailableFilterTags),
      switchMap(
        ({ attributes, suggestionFilterBys }: { attributes: FilterAttributeConfig[]; suggestionFilterBys: SuggestionFilterBy[] }) => {
          const networkRequests = attributes.map((attributeConfig: FilterAttributeConfig) => {
            const criteria = new SuggestionCriteria({
              filterBys: suggestionFilterBys,
              columnName: attributeConfig.attribute,
              query: '',
              size: 100,
            });
            return this.integrationMetaService.getFilterSuggestionsV2(criteria);
          });
          return forkJoin(networkRequests).pipe(
            switchMap((suggestionSearchResponses: SuggestionSearchResponse[]) => {
              const filterTags = [];
              suggestionSearchResponses.forEach((suggestionSearchResponse: SuggestionSearchResponse, index: number) => {
                const availableTags = suggestionSearchResponse.values.map((item: SuggestionSearchItem) =>
                  deserialize(Tag, {
                    name: item.value,
                    label: item.value,
                    type: TAGS_TYPE.BOOLEAN,
                    attribute: attributes[index].attribute,
                    isHidden: attributes[index].isHidden,
                  }),
                );
                filterTags.push(...availableTags);
              });
              return of(IntegrationMetaActions.getAvailableFilterTagsSuccess({ filterTags }));
            }),
            catchError((error: WebError) => [
              IntegrationMetaActions.cleanAvailableFilterTags(),
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.MODAL,
                message: this.i18nService.translate('COMMON_MESSAGES.QUICK_FILTERS_ERROR_MSG', {
                  reason: error.getFullReason(),
                }),
              }),
            ]),
          );
        },
      ),
    ),
  );

  /**
   * loadCategories$
   * @type {Observable<Action>}
   * @memberof IntegrationMetaEffects
   */
  public loadCategories$: Observable<Action> = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(UserPreferenceActions.loadUserPreferenceSuccess)),
      this.store.select(RbacSelectors.hasUserScopes),
      this.store.select(UserPreferenceSelectors.getUserAccountRoles),
      this.store.select(UserPreferenceFeatureControlsSelectors.isWorkspaceOneRbacEnabled),
    ]).pipe(
      filter(([_action, hasUserScopes, userAccountRoles, isWorkspaceOneRbacEnabled]: [Action, boolean, AccountRole[], boolean]) => {
        return hasUserScopes && (!isWorkspaceOneRbacEnabled || userAccountRoles.length > 0);
      }),
      switchMap(() => {
        return this.integrationMetaService.getIntegrationMetadata().pipe(
          map((categories: Category[]) => IntegrationMetaActions.loadCategoriesSuccess({ categories })),
          catchError(() => of(IntegrationMetaActions.loadCategoriesFailure())),
        );
      }),
    ),
  );

  /**
   * getPrecomputedAggregations$
   * @type {Observable<Action>}
   * @memberof IntegrationMetaEffects
   */
  public getPrecomputedAggregations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationMetaActions.getPrecomputedAggregations),
      withLatestFrom(this.store.select(IntegrationMetaSelectors.getCategoriesByCategoryId)),
      switchMap(
        ([{ categoryId }, categoriesByCategoryId]: [
          ReturnType<typeof IntegrationMetaActions.getPrecomputedAggregations>,
          CategoryIndex,
        ]) => {
          const category = categoriesByCategoryId[categoryId];
          return this.integrationMetaService.getPrecomputedAggregations(category.integration.name, category.entity.name).pipe(
            map((precomputedAggregations: PrecomputedAggregation[]) => {
              return IntegrationMetaActions.getPrecomputedAggregationsSuccess({
                categoryId,
                precomputedAggregations,
              });
            }),
            catchError(() => of(IntegrationMetaActions.getPrecomputedAggregationsFailure())),
          );
        },
      ),
    ),
  );

  /**
   * getAggregationAttributes
   * @type {Observable<Action>}
   * @memberof IntegrationMetaEffects
   */
  public getAggregationAttributes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationMetaActions.getAggregationAttributes),
      switchMap(({ id, category }: ReturnType<typeof IntegrationMetaActions.getAggregationAttributes>) => {
        return this.integrationMetaService.getAggregationAttributes(id).pipe(
          map((aggregationAttributesResponse: AggregationAttributesResponse) => {
            return IntegrationMetaActions.getAggregationAttributesSuccess({
              category,
              aggregationAttributesResponse,
            });
          }),
          catchError(() => of(IntegrationMetaActions.getAggregationAttributesFailure())),
        );
      }),
    ),
  );

  /**
   * loadCustomAttributeIdentifiers
   * @type {Observable<Action>}
   * @memberof IntegrationMetaEffects
   */
  public loadCustomAttributeIdentifiers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationMetaActions.loadCustomAttributeIdentifiers),
      switchMap(({ customAttributeIdentifiersSearchRequest }: ReturnType<typeof IntegrationMetaActions.loadCustomAttributeIdentifiers>) => {
        return this.integrationMetaService.getCustomAttributeIdentifiers(customAttributeIdentifiersSearchRequest).pipe(
          map((customAttributeIdentifiersSearchResponse: CustomAttributeIdentifiersSearchResponse) => {
            return IntegrationMetaActions.loadCustomAttributeIdentifiersSuccess({
              customAttribute: customAttributeIdentifiersSearchRequest.customAttribute,
              customAttributeIdentifiersSearchResponse,
            });
          }),
          catchError((error: WebError) => [
            AlertBannerActions.showAlertBanner({
              alertType: ALERT_BANNER_TYPE.DANGER,
              target: AlertBannerTarget.PAGE,
              message: this.i18nService.translate('DASHBOARD_ACTIONS.LOAD_IDENTIFIERS_FAILURE_MSG', {
                reason: error.getFullReason(),
              }),
            }),
            IntegrationMetaActions.loadCustomAttributeIdentifiersFailure(),
          ]),
        );
      }),
    ),
  );

  /**
   * loadCustomAttributesIdentifierAttributes
   * @type {Observable<Action>}
   * @memberof IntegrationMetaEffects
   */
  public loadCustomAttributesIdentifierAttributes$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationMetaActions.loadCustomAttributesIdentifierAttributes),
      switchMap(
        ({
          customAttributeIdentifiersByAttributeName,
        }: ReturnType<typeof IntegrationMetaActions.loadCustomAttributesIdentifierAttributes>) => {
          return this.integrationMetaService.getCustomAttributesIdentifierAttributes(customAttributeIdentifiersByAttributeName).pipe(
            switchMap(
              ({
                customAttributeIdentifierAttributesByKey,
                customAttributeIdentifiersSearchResponses,
              }: CustomAttributeIdentifiersAndColumnsResponse) => {
                const actions: Action[] = [];
                // set all identifier attributes
                actions.push(
                  IntegrationMetaActions.loadCustomAttributesIdentifierAttributesSuccess({
                    customAttributeIdentifierAttributesByKey,
                  }),
                );
                // set all identifiers
                customAttributeIdentifiersSearchResponses?.forEach(
                  ({
                    customAttribute,
                    customAttributeIdentifiersSearchResponse,
                  }: {
                    customAttribute: string;
                    customAttributeIdentifiersSearchResponse: CustomAttributeIdentifiersSearchResponse;
                  }) => {
                    actions.push(
                      IntegrationMetaActions.loadCustomAttributeIdentifiersSuccess({
                        customAttribute,
                        customAttributeIdentifiersSearchResponse,
                      }),
                    );
                  },
                );
                return actions;
              },
            ),
            catchError((error: WebError) => [
              AlertBannerActions.showAlertBanner({
                alertType: ALERT_BANNER_TYPE.DANGER,
                target: AlertBannerTarget.PAGE,
                message: this.i18nService.translate('DASHBOARD_ACTIONS.LOAD_ATTRIBUTES_FAILURE_MSG', {
                  reason: error.getFullReason(),
                }),
              }),
            ]),
          );
        },
      ),
    ),
  );

  /**
   * Creates an instance of IntegrationMetaEffects.
   * @param {Actions} actions$
   * @param {IntegrationMetaService} integrationMetaService
   * @param {Store} store
   * @param {I18NService} i18nService
   * @memberof IntegrationMetaEffects
   */
  constructor(
    private actions$: Actions,
    private integrationMetaService: IntegrationMetaService,
    private store: Store,
    private i18nService: I18NService,
  ) {}
}
