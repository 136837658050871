/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SlideOverSize } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { cloneDeep, isEqual } from 'lodash-es';
import { BehaviorSubject, distinctUntilChanged, filter, map } from 'rxjs';

import { CoreAppState, IntegrationMetaActions, IntegrationMetaSelectors } from '@ws1c/intelligence-core/store';
import {
  Column,
  DateRangeFilters,
  FilterAttributeConfig,
  FilterRule,
  RuleGroup,
  SuggestionFilterBy,
  Tag,
  TrendDateRange,
} from '@ws1c/intelligence-models';

/**
 * FilterGroupSlideOverComponent
 * @export
 * @class FilterGroupSlideOverComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-filter-group-slide-over',
  templateUrl: 'filter-group-slide-over.component.html',
  styleUrls: ['filter-group-slide-over.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterGroupSlideOverComponent implements OnChanges, OnInit {
  @Input() public editable: boolean;
  @Input() public defaultRuleGroup: RuleGroup;
  @Input() public defaultTrendDateRange: TrendDateRange;
  @Input() public filterColumns: Column[];
  @Input() public isCrossCategory: boolean = false;
  @Input() public isTrendDateRangeReadonly?: boolean = false;
  @Input() public showTrendDateRange?: boolean = true;
  @Input() public maxCustomRange?: number = 28;
  @Input() public showFilters?: boolean = true;
  @Input() public customTimePeriodHelpText?: string;
  @Input() public filterTags?: Tag[]; // When filterTags is set, filterTags would be set from this input
  @Input() public filterAttributes?: FilterAttributeConfig[]; // When filterAttributes is set, filterTags would be got from suggestion API
  @Input() public defaultSelectedTags?: Tag[];
  @Input() public showIncludesAllText: boolean = false;
  @Input() public suggestionFilterBys: SuggestionFilterBy[];
  @Input() public selectedTrendDateRange?: TrendDateRange;
  @Output() public applyFilter: EventEmitter<DateRangeFilters> = new EventEmitter<DateRangeFilters>();

  public filterTrendDateRange: TrendDateRange;
  public filterRuleGroup: RuleGroup;
  public dateRangeFilters$: BehaviorSubject<DateRangeFilters> = new BehaviorSubject<DateRangeFilters>({});
  public filterSlideOverVisible: boolean = false;
  public filterTagsClickable = false;
  public filterCount: number = 0;

  public readonly destroyRef: DestroyRef = inject(DestroyRef);
  public readonly FILTER_SLIDE_OVER_SIZE: SlideOverSize = SlideOverSize.MD;
  public readonly FILTER_CONDITIONS_FOR_TAGS: string[] = [FilterRule.FILTER_CONDITION.includes, FilterRule.FILTER_CONDITION.equals];

  private detectRef = inject(ChangeDetectorRef);
  private store = inject(Store<CoreAppState>);

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof FilterGroupSlideOverComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.filterAttributes && this.filterAttributes?.length) ||
      (changes.defaultRuleGroup && this.defaultRuleGroup && this.filterAttributes?.length)
    ) {
      this.filterTags = [];
      this.getAvailableTags();
    }

    if (this.showFilters && (changes.defaultRuleGroup || changes.filterTags || changes.filterColumns) && this.filterColumns) {
      this.filterRuleGroup = undefined;
      this.filterTagsClickable = false;
      this.dateRangeFilters$.next({});
      this.onFilterChange(this.defaultRuleGroup);
    }

    if (changes.defaultTrendDateRange && !this.selectedTrendDateRange) {
      this.onDateRangeChange(this.defaultTrendDateRange);
    }
  }

  /**
   * ngOnInit
   * @memberof FilterGroupSlideOverComponent
   */
  public ngOnInit() {
    this.getAvailableTags();
    this.filterTrendDateRange = this.selectedTrendDateRange ?? this.defaultTrendDateRange;
    this.store
      .select(IntegrationMetaSelectors.getFilterTags)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((filterTags: Tag[]) => {
        this.filterTags = filterTags;
        this.parseTagsBasedOnRuleGroup(this.defaultRuleGroup);
      });
    this.dateRangeFilters$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((dateRangeFilters: DateRangeFilters) => cloneDeep(dateRangeFilters)),
        distinctUntilChanged(isEqual),
        filter((dataRangeFilters: DateRangeFilters) => this.showTrendDateRange && !!dataRangeFilters.trendDateRange),
      )
      .subscribe(({ ruleGroup, trendDateRange }: DateRangeFilters) => {
        this.applyFilter.emit({ ruleGroup, trendDateRange });
      });
  }

  /**
   * onFilterChange
   * @param {RuleGroup} ruleGroup
   * @memberof FilterGroupSlideOverComponent
   */
  public onFilterChange(ruleGroup: RuleGroup) {
    this.parseTagsBasedOnRuleGroup(ruleGroup);
    this.filterRuleGroup = cloneDeep(ruleGroup);
    this.dateRangeFilters$.next({ ruleGroup, trendDateRange: this.filterTrendDateRange });
  }

  /**
   * onDateRangeChange
   * @param {TrendDateRange} trendDateRange
   * @memberof FilterGroupSlideOverComponent
   */
  public onDateRangeChange(trendDateRange: TrendDateRange) {
    this.filterTrendDateRange = trendDateRange;
    this.dateRangeFilters$.next({ ruleGroup: this.filterRuleGroup, trendDateRange });
  }

  /**
   * setFilterSlideOverVisible
   * @param {boolean} flag
   * @memberof FilterGroupSlideOverComponent
   */
  public setFilterSlideOverVisible(flag: boolean) {
    this.filterSlideOverVisible = flag;
  }

  /**
   * onTagFilterChange
   * @param {Record<string, Tag>} selectedTagsByName
   * @memberof FilterGroupSlideOverComponent
   */
  public onTagFilterChange(selectedTagsByName: Record<string, Tag>) {
    if (!this.filterColumns || !this.filterTagsClickable) {
      return;
    }
    this.filterRuleGroup = RuleGroup.convertTagsToRuleGroup(this.filterTags, selectedTagsByName, this.filterColumns, this.filterRuleGroup);
    this.dateRangeFilters$.next({ ruleGroup: this.filterRuleGroup, trendDateRange: this.filterTrendDateRange });
  }

  /**
   * getAvailableTags
   * @memberof FilterGroupSlideOverComponent
   */
  public getAvailableTags() {
    this.store.dispatch(IntegrationMetaActions.cleanAvailableFilterTags());
    if (this.filterAttributes?.length) {
      this.store.dispatch(
        IntegrationMetaActions.getAvailableFilterTags({
          attributes: this.filterAttributes,
          suggestionFilterBys: RuleGroup.convertRuleGroupToSuggestionFilterBy(this.defaultRuleGroup),
        }),
      );
    }
  }

  /**
   * parseTagsBasedOnRuleGroup
   * @private
   * @param {RuleGroup} ruleGroup
   * @memberof FilterGroupSlideOverComponent
   */
  private parseTagsBasedOnRuleGroup(ruleGroup: RuleGroup) {
    if (!ruleGroup) {
      return;
    }
    ruleGroup.rules = ruleGroup.rules.filter((rule: FilterRule) => !rule.isMissingFields());
    this.filterCount = ruleGroup.getFilterCount(undefined, true);
    this.filterTagsClickable = this.filterTags?.length > 0 && RuleGroup.canRuleGroupConvertToTags(ruleGroup, this.filterTags);
    this.defaultSelectedTags = RuleGroup.convertRuleGroupToTags(ruleGroup, this.filterTags);
    this.detectRef.detectChanges();
  }
}
