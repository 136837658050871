/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { RuleGroupOperator } from '@dpa/ui-common';

import { AppConfig, IntelligenceCommonModule } from '@ws1c/intelligence-common';
import { CustomAttributeFilterGroupRuleComponent } from '@ws1c/intelligence-core/components/query-builder/custom-attribute-filter-group-rule/custom-attribute-filter-group-rule.component';
import { RuleTextComponent } from '@ws1c/intelligence-core/components/query-builder/rule-text/rule-text.component';
import { Category, ColumnIndex, CustomAttributeFilterRule, RuleGroup, RuleStatus, SuggestionFilterBy } from '@ws1c/intelligence-models';

/**
 * CustomAttributeFilterGroupComponent
 *
 * Terminology:
 * The attributes, which we get from /v2/meta/integration/INTEGRATION/entity/ENTITY/attributes, of data_type STRUCTMAP has to support map
 * of key-value pairs of user-defined attributes. This STRUCTMAP data_type attribute is identified as customAttribute.
 * Each customAttribute will have it's own child Rule Group to accomodate multiple user-defined attributes inside it. This child Rule
 * Group is identified as customAttributeRuleGroup.
 * The customAttributeRuleGroup will have multiple rules defining each user-defined attribute key-value pair. Each rule is
 * customAttributeFilterRule.
 *
 * @export
 * @class CustomAttributeFilterGroupComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-custom-attribute-filter-group',
  templateUrl: 'custom-attribute-filter-group.component.html',
  styleUrls: ['custom-attribute-filter-group.component.scss'],
  standalone: true,
  imports: [IntelligenceCommonModule, CustomAttributeFilterGroupRuleComponent, RuleTextComponent],
})
export class CustomAttributeFilterGroupComponent implements OnInit {
  @Input() public parentFilterRule: CustomAttributeFilterRule;
  @Input() public allColumnsByName?: ColumnIndex = {};
  @Input() public suggestionFilterBys: SuggestionFilterBy[];
  @Input() public suggestionCategory?: Category;
  @Input() public delimiterSupported?: boolean = false;
  @Output() public filterGroupChange: EventEmitter<void> = new EventEmitter<void>();

  public focusedFilterRule: CustomAttributeFilterRule;

  public readonly RULE_STATUS = RuleStatus;
  public readonly MAX_ALLOWED_CUSTOM_ATTRIBUTE_RULES_PER_FILTER = AppConfig.MAX_ALLOWED_CUSTOM_ATTRIBUTE_RULES_PER_FILTER;

  private readonly changeRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  /**
   * ngOnInit
   * @memberof CustomAttributeFilterGroupComponent
   */
  public ngOnInit() {
    this.parentFilterRule.customAttributeRuleGroup = new RuleGroup({
      operator: this.parentFilterRule.customAttributeRuleGroup?.operator ?? RuleGroupOperator.AND,
      rules: this.parentFilterRule.customAttributeRuleGroup?.rules ?? [new CustomAttributeFilterRule()],
    });
  }

  /**
   * getNewFilterRule
   * @returns {CustomAttributeFilterRule}
   * @memberof CustomAttributeFilterGroupComponent
   */
  public getNewFilterRule = (): CustomAttributeFilterRule => new CustomAttributeFilterRule();

  /**
   * isFilterRuleActive
   * @param {CustomAttributeFilterRule} filterRule
   * @returns {boolean}
   * @memberof CustomAttributeFilterGroupComponent
   */
  public isFilterRuleActive = (filterRule: CustomAttributeFilterRule): boolean => this.focusedFilterRule === filterRule;

  /**
   * isFilterRuleInvalid
   * @param {CustomAttributeFilterRule} filterRule
   * @returns {boolean}
   * @memberof CustomAttributeFilterGroupComponent
   */
  public isFilterRuleInvalid = (filterRule: CustomAttributeFilterRule): boolean =>
    filterRule.getStatus(this.allColumnsByName) === this.RULE_STATUS.INVALID;

  /**
   * isFilterRuleValid
   * @param {CustomAttributeFilterRule} filterRule
   * @returns {boolean}
   * @memberof CustomAttributeFilterGroupComponent
   */
  public isFilterRuleValid(filterRule: CustomAttributeFilterRule): boolean {
    return filterRule.isValid(this.allColumnsByName);
  }

  /**
   * onQueryChange
   * @memberof CustomAttributeFilterGroupComponent
   */
  public onQueryChange() {
    this.filterGroupChange.emit();
  }

  /**
   * onFocus
   * @param {CustomAttributeFilterRule} filterRule
   * @memberof CustomAttributeFilterGroupComponent
   */
  public onFocus(filterRule: CustomAttributeFilterRule) {
    this.focusedFilterRule = filterRule;
  }

  /**
   * onFocusOut
   * @param {CustomAttributeFilterRule} filterRule
   * @param {FocusEvent} event
   * @memberof CustomAttributeFilterGroupComponent
   */
  public onFocusOut(filterRule: CustomAttributeFilterRule, event: FocusEvent) {
    const currentTarget = event.currentTarget as HTMLElement;
    const relatedTarget = event.relatedTarget as HTMLElement;

    // when switching from one field to another in the same group, ignore onFocusOut event
    if (relatedTarget && currentTarget.contains(relatedTarget)) {
      return;
    }

    // Fix for ExpressionChangedAfterItHasBeenCheckedError which is happening on pressing [ESC] key when
    // filter group key slector dropdown is open and focussed.
    setTimeout(() => {
      this.focusedFilterRule = null;
      this.changeRef.detectChanges();
    }, 100);
    filterRule.isDraft = false;
  }

  /**
   * onFilterRuleValuesSelect
   * @param {CustomAttributeFilterRule} filterRule
   * @param {HTMLElement} filterRuleContainerNode
   * @memberof CustomAttributeFilterGroupComponent
   */
  public onFilterRuleValuesSelect(filterRule: CustomAttributeFilterRule, filterRuleContainerNode: HTMLElement) {
    this.focusedFilterRule = filterRule;
    setTimeout(() => {
      (filterRuleContainerNode.querySelector('dpa-custom-attribute-filter-group-rule dpa-filter-value input') as any)?.focus();
    }, 30);
  }

  /**
   * enableFilterRuleEdit
   * @param {CustomAttributeFilterRule} filterRule
   * @param {HTMLElement} filterRuleContainerNode
   * @memberof CustomAttributeFilterGroupComponent
   */
  public enableFilterRuleEdit(filterRule: CustomAttributeFilterRule, filterRuleContainerNode: HTMLElement) {
    this.focusedFilterRule = filterRule;
    setTimeout(() => {
      (filterRuleContainerNode.querySelector('dpa-custom-attribute-filter-group-rule') as any)?.focus();
    }, 30);
  }
}
