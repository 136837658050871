/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateTimeFormat, PagedRequest, SortOn } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ConnectionActions } from '@dpa-shared-merlot/store';
import { I18NService } from '@ws1c/intelligence-common/services';
import { CoreAppState } from '@ws1c/intelligence-core/store';
import { AutomationCommonSelectors } from '@ws1c/intelligence-core/store/automation-common';
import {
  Automation,
  AutomationActionEvent,
  AutomationActionType,
  AutomationSearchResponse,
  COLUMN_NAMES,
  LayoutType,
  SourceObjectType,
  Trend,
} from '@ws1c/intelligence-models';

/**
 * Automation List View
 * @export
 * @class AutomationGridListComponent
 */
@Component({
  selector: 'dpa-automation-grid-list',
  templateUrl: 'automation-grid-list.component.html',
  styleUrls: ['automation-grid-list.component.scss'],
})
export class AutomationGridListComponent {
  @Input() public pageSize: number;
  @Input() public loading?: boolean = true;
  @Input() public sortOns?: SortOn[] = [];
  @Input() public response: AutomationSearchResponse;
  @Input() public showActions?: boolean = false;
  @Input() public hasIncidentViewPerm: boolean;
  @Input() public runHistoryTrend: Trend;
  @Output() public sortChange: EventEmitter<SortOn[]> = new EventEmitter();
  @Output() public pageChange: EventEmitter<PagedRequest> = new EventEmitter();
  @Output() public singleRowAction: EventEmitter<AutomationActionEvent> = new EventEmitter();
  @Output() public searchItem: EventEmitter<string> = new EventEmitter();
  @Output() public refresh: EventEmitter<any> = new EventEmitter();

  public hasRunHistoryDashboardPerm$: Observable<boolean>;
  public readonly AUTOMATION_ACTION_TYPE = AutomationActionType;
  public readonly COLUMN_NAMES = COLUMN_NAMES;
  public readonly DateTimeFormat = DateTimeFormat;
  public readonly LayoutType = LayoutType;
  public readonly AutomationSourceObjectType = SourceObjectType;

  /**
   * Creates an instance of AutomationGridListComponent.
   * @param {Store<CoreAppState>} store
   * @param {I18NService} i18nService
   * @memberof AutomationGridListComponent
   */
  constructor(
    private store: Store<CoreAppState>,
    private i18nService: I18NService,
  ) {
    this.hasRunHistoryDashboardPerm$ = this.store.select(AutomationCommonSelectors.hasRunHistoryDashboardPerm);
  }

  /**
   * onSortChange
   * @param {SortOn[]} sorts
   * @memberof AutomationGridListComponent
   */
  public onSortChange(sorts: SortOn[]) {
    this.sortChange.emit(sorts);
  }

  /**
   * onPageChange
   * @param {PagedRequest} page
   * @memberof AutomationGridListComponent
   */
  public onPageChange(page: PagedRequest) {
    this.pageChange.emit(page);
  }

  /**
   * onSingleRowAction
   * @param {AutomationActionType} action
   * @param {Automation} data
   * @memberof AutomationGridListComponent
   */
  public onSingleRowAction(action: AutomationActionType, data: Automation) {
    this.singleRowAction.emit({
      action,
      data,
    } as AutomationActionEvent);
  }

  /**
   * onRefresh
   * @memberof AutomationGridListComponent
   */
  public onRefresh() {
    this.refresh.emit();
  }

  /**
   * isToggleDisabled
   * @returns {(automation: Automation) => boolean}
   * @memberof AutomationGridListComponent
   */
  public isToggleDisabled(): (automation: Automation) => boolean {
    return (automation: Automation) => !automation.active;
  }

  /**
   * isSourceObjectAutomation
   * @param {Automation} automation
   * @returns {boolean}
   * @memberof AutomationGridListComponent
   */
  public isSourceObjectAutomation = (automation: Automation): boolean => automation?.hasSourceObject;

  /**
   * isNotSourceObjectAutomationOfType
   * @param {AutomationSourceObjectType} type
   * @returns {boolean}
   * @memberof AutomationGridListComponent
   */
  public isNotSourceObjectAutomationOfType =
    (type: SourceObjectType) =>
    (automation: Automation): boolean => {
      return !this.isSourceObjectAutomation(automation) || automation.sourceObjectType !== type;
    };

  /**
   * isRunButtonHidden
   * @param {Automation} automation
   * @returns {boolean}
   * @memberof AutomationGridListComponent
   */
  public isRunButtonHidden = (automation: Automation): boolean => {
    return this.isSourceObjectAutomation(automation) || automation?.isAutomatic || automation?.isCustomTarget;
  };

  /**
   * isRunButtonDisabled
   * @param {Automation} automation
   * @returns {boolean}
   * @memberof AutomationGridListComponent
   */
  public isRunButtonDisabled = (automation: Automation): boolean => !automation?.active || automation.hasSourceObject;

  /**
   * getLabelGenerator
   * @returns {(automation: Automation) => string}
   * @memberof AutomationGridListComponent
   */
  public getLabelGenerator(): (automation: Automation) => string {
    return this.getLabel.bind(this);
  }

  /**
   * onNavigateToAutomation
   * @param {string} automationId
   * @memberof AutomationGridListComponent
   */
  public onNavigateToAutomation(automationId: string) {
    this.store.dispatch(ConnectionActions.goToAutomationDetailPage({ automationId }));
  }

  /**
   * getLabel
   * @returns {string}
   * @memberof AutomationGridListComponent
   */
  private getLabel(): string {
    return this.i18nService.translate('AUTOMATION_ACTIONS.DISABLE');
  }
}
