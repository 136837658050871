/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Tab } from '@dpa/ui-common';
import { createSelector, MemoizedSelector, Selector } from '@ngrx/store';

import { AppLoadsSelectors } from '@dpa-shared-merlot/store/app-loads/app-loads.selectors';
import { AppsDashboardSelectors } from '@dpa-shared-merlot/store/dashboard/apps/apps-dashboard.selectors';
import { MerlotState } from '@dpa-shared-merlot/store/merlot.store';
import { getDefaultedTrendDate } from '@ws1c/dashboard-common/utils';
import { DashboardSelectors, helpers } from '@ws1c/intelligence-core/store/dashboard/dashboard.selectors';
import {
  COLUMN_NAMES,
  ComparisonQueryResponse,
  CustomReportPreviewSearchResponse,
  DeviceUser,
  PreviewReportContentRequest,
  RuleGroup,
  StandardDashboardRequest,
  StandardWidgetSubtype,
  Trend,
  TrendDateRange,
} from '@ws1c/intelligence-models';
import * as userProfileSelectorHelpers from './user-profile-selector-helpers';
import { UserProfileInterface, UserProfileState } from './user-profile.state';

/**
 * UserProfileSelectors
 *
 * @export
 * @class UserProfileSelectors
 */
export class UserProfileSelectors {
  /**
   * selectUserProfileState
   * @static
   * @type {Selector<MerlotState, UserProfileState>}
   * @memberof UserProfileSelectors
   */
  // eslint-disable-next-line max-len
  public static selectUserProfileState: Selector<MerlotState, UserProfileState> = (state: MerlotState) =>
    state.standardDashboardState.userProfileState;

  /**
   * getAppDetailUser
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof UserProfileSelectors
   */
  public static getAppDetailUser: MemoizedSelector<MerlotState, string> = createSelector(
    UserProfileSelectors.selectUserProfileState,
    (state: UserProfileState) => state.appDetailUser,
  );

  /**
   * getAppDetailUserProfileTab
   * @static
   * @type {MemoizedSelector<MerlotState, string>}
   * @memberof UserProfileSelectors
   */
  public static getAppDetailUserProfileTab: MemoizedSelector<MerlotState, string> = createSelector(
    UserProfileSelectors.selectUserProfileState,
    (state: UserProfileState) => state.appDetailUserProfileTab,
  );

  /**
   * getAppDetailUserProfileVisibleTabs
   * @static
   * @type {MemoizedSelector<MerlotState, Tab[]>}
   * @memberof UserProfileSelectors
   */
  public static getAppDetailUserProfileVisibleTabs: MemoizedSelector<MerlotState, Tab[]> = createSelector(
    UserProfileSelectors.selectUserProfileState,
    (state: UserProfileState) => state.appDetailUserProfileVisibleTabs,
  );

  /**
   * getUserProfileTotalAppLoadsData
   * @static
   * @type  {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof UserProfileSelectors
   */
  public static getUserProfileTotalAppLoadsData: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(
        trendsByWidgetSubtype,
        null,
        StandardWidgetSubtype.USER_PROFILE_TOTAL_APP_LOADS_COUNT,
      ) as ComparisonQueryResponse;
    },
  );

  /**
   * getUserProfileTotalCrashesData$
   * @static
   * @type  {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof UserProfileSelectors
   */
  public static getUserProfileTotalCrashesData: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(trendsByWidgetSubtype, null, StandardWidgetSubtype.USER_PROFILE_TOTAL_CRASHES_COUNT);
    },
  );

  /**
   * getUserProfileTotalNetworkErrorsData$
   * @static
   * @type  {MemoizedSelector<MerlotState, ComparisonQueryResponse>}
   * @memberof UserProfileSelectors
   */
  public static getUserProfileTotalNetworkErrorsData: MemoizedSelector<MerlotState, ComparisonQueryResponse> = createSelector(
    DashboardSelectors.getStandardDashboardData,
    (trendsByWidgetSubtype: Map<string, Trend>) => {
      return helpers.getOffsetCounterData(trendsByWidgetSubtype, null, StandardWidgetSubtype.USER_PROFILE_TOTAL_NETWORK_ERRORS_COUNT);
    },
  );

  /**
   * getStandardAppUsersDashboardRequest
   * @static
   * @type  {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof UserProfileSelectors
   */
  public static getStandardAppUsersDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    AppLoadsSelectors.getSelectedAppId,
    AppLoadsSelectors.getSelectedAppName,
    AppLoadsSelectors.getSelectedApteligentId,
    AppLoadsSelectors.getSelectedAppPlatform,
    AppsDashboardSelectors.getAppDashboardFilterRuleGroup,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    (
      appId: string,
      appName: string,
      apteligentId: string,
      appPlatform: string,
      appDashboardFilterRuleGroup: RuleGroup,
      appDashboardTrendDateRange: TrendDateRange,
    ) =>
      userProfileSelectorHelpers.helpers.buildStandardAppUsersDashboardRequest(
        appId,
        appName,
        apteligentId,
        appPlatform,
        appDashboardFilterRuleGroup,
        appDashboardTrendDateRange,
      ),
  );

  /**
   * getStandardUserProfileDashboardRequest
   * @static
   * @type  {MemoizedSelector<MerlotState, StandardDashboardRequest>}
   * @memberof UserProfileSelectors
   */
  public static getStandardUserProfileDashboardRequest: MemoizedSelector<MerlotState, StandardDashboardRequest> = createSelector(
    AppLoadsSelectors.getSelectedAppId,
    AppLoadsSelectors.getSelectedAppName,
    AppLoadsSelectors.getSelectedApteligentId,
    AppLoadsSelectors.getSelectedAppPlatform,
    AppsDashboardSelectors.getAppDashboardFilterRuleGroup,
    AppsDashboardSelectors.getAppDashboardTrendDateRange,
    UserProfileSelectors.getAppDetailUser,
    UserProfileSelectors.getAppDetailUserProfileTab,
    (
      appId: string,
      appName: string,
      apteligentId: string,
      appPlatform: string,
      appDashboardFilterRuleGroup: RuleGroup,
      appDashboardTrendDateRange: TrendDateRange,
      appUser: string,
      deviceModel: string,
    ) =>
      userProfileSelectorHelpers.helpers.buildStandardUserProfileDashboardRequest(
        appId,
        appName,
        apteligentId,
        appPlatform,
        appDashboardFilterRuleGroup,
        appDashboardTrendDateRange,
        appUser,
        deviceModel,
      ),
  );

  /**
   * getTrendDateRange
   */
  public static getTrendDateRange: MemoizedSelector<MerlotState, TrendDateRange> = createSelector(
    UserProfileSelectors.selectUserProfileState,
    (state: UserProfileState) => getDefaultedTrendDate(state.userProfile.trendDateRange),
  );

  /**
   * getUserProfile
   * @static
   * @type {MemoizedSelector<MerlotState, UserProfileInterface>}
   * @memberof UserProfileSelectors
   */
  public static getUserProfile: MemoizedSelector<MerlotState, UserProfileInterface> = createSelector(
    UserProfileSelectors.selectUserProfileState,
    (state: UserProfileState) => state.userProfile,
  );

  /**
   * getUserProfileExperienceDeviceRequest
   * @static
   * @type {MemoizedSelector<MerlotState, PreviewReportContentRequest[]>}
   * @memberof UserProfileSelectors
   */
  public static getUserProfileExperienceDeviceRequest: MemoizedSelector<MerlotState, PreviewReportContentRequest[]> = createSelector(
    UserProfileSelectors.getUserProfile,
    (state: UserProfileInterface) => state.experienceDeviceRequests,
  );

  /**
   * getUserProfileExperienceTableResponse
   * @static
   * @type {MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse[]>}
   * @memberof UserProfileSelectors
   */
  public static getUserProfileExperienceTableResponse: MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse[]> = createSelector(
    UserProfileSelectors.getUserProfile,
    (state: UserProfileInterface) => state.experienceDeviceResponses,
  );

  /**
   * getUserProfileExperienceMobileDeviceRequest
   * @static
   * @type {MemoizedSelector<MerlotState, PreviewReportContentRequest>}
   * @memberof UserProfileSelectors
   */
  public static getUserProfileExperienceMobileDeviceRequest: MemoizedSelector<MerlotState, PreviewReportContentRequest> = createSelector(
    UserProfileSelectors.getUserProfile,
    (state: UserProfileInterface) => state.experienceMobileDeviceRequest,
  );

  /**
   * getUserProfileExperienceMobileResponse
   * @static
   * @type {MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse>}
   * @memberof UserProfileSelectors
   */
  public static getUserProfileExperienceMobileResponse: MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse> = createSelector(
    UserProfileSelectors.getUserProfile,
    DashboardSelectors.getStandardDashboardData,
    (state: UserProfileInterface, standardDashboardData: Map<string, Trend>) => {
      return helpers.getUniqMobileDevice(
        state.experienceMobileDeviceResponse,
        standardDashboardData.get(StandardWidgetSubtype.DEEM_MOBILE_APPS),
      );
    },
  );

  /**
   * getUserProfileExperienceAppResponse
   * @static
   * @type {MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse[]>}
   * @memberof UserProfileSelectors
   */
  public static getUserProfileExperienceAppResponse: MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse[]> = createSelector(
    UserProfileSelectors.getUserProfile,
    (state: UserProfileInterface) => state.experienceAppResponses,
  );

  /**
   * getUserProfileExperienceAppRequest
   * @static
   * @type {MemoizedSelector<MerlotState, PreviewReportContentRequest[]>}
   * @memberof UserProfileSelectors
   */
  public static getUserProfileExperienceAppRequest: MemoizedSelector<MerlotState, PreviewReportContentRequest[]> = createSelector(
    UserProfileSelectors.getUserProfile,
    (state: UserProfileInterface) => state.experienceAppRequests,
  );

  /**
   * getDeviceResponse
   * @static
   * @type {MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse>}
   * @memberof UserProfileSelectors
   */
  public static getDeviceResponse: MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse> = createSelector(
    UserProfileSelectors.getUserProfileExperienceTableResponse,
    (responses: CustomReportPreviewSearchResponse[]) =>
      userProfileSelectorHelpers.getLatestFromResponse(
        responses[0],
        COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_airwatch_device_guid,
        COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_device_score_calculation_timestamp,
      ),
  );

  /**
   * getAppResponse
   * @static
   * @type {MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse>}
   * @memberof UserProfileSelectors
   */
  public static getAppResponse: MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse> = createSelector(
    UserProfileSelectors.getUserProfileExperienceTableResponse,
    (responses: CustomReportPreviewSearchResponse[]) => responses[1],
  );

  /**
   * getDesktopAppResponse
   * @static
   * @type {MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse>}
   * @memberof UserProfileSelectors
   */
  public static getDesktopAppResponse: MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse> = createSelector(
    UserProfileSelectors.getUserProfileExperienceAppResponse,
    (responses: CustomReportPreviewSearchResponse[]) =>
      userProfileSelectorHelpers.getLatestFromResponse(
        responses[0],
        COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_app_name,
        COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_desktop_app_score_calculation_timestamp,
      ),
  );

  /**
   * getMobileAppResponse
   * @static
   * @type {MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse>}
   * @memberof UserProfileSelectors
   */
  public static getMobileAppResponse: MemoizedSelector<MerlotState, CustomReportPreviewSearchResponse> = createSelector(
    UserProfileSelectors.getUserProfileExperienceAppResponse,
    (responses: CustomReportPreviewSearchResponse[]) =>
      userProfileSelectorHelpers.getLatestFromResponse(
        responses[1],
        COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_app_package_id,
        COLUMN_NAMES.byFullyQualifiedName.internal_experiencescore_mobile_app_score_calculation_timestamp,
      ),
  );

  /**
   * getUserProfileInfo
   * @static
   * @type {MemoizedSelector<MerlotState, DeviceUser>}
   * @memberof UserProfileSelectors
   */
  public static getUserProfileInfo: MemoizedSelector<MerlotState, DeviceUser> = createSelector(
    UserProfileSelectors.selectUserProfileState,
    (state: UserProfileState) => state.userProfile?.user,
  );
}
