<ng-container *ngLet="activeAutomationType$ | async as activeAutomationType">
  <ng-container *ngLet="AUTOMATION_TYPE_KEY_MAP[activeAutomationType] as activeAutomationTypeKey">
    @if (copyAutomationDialogOpen$ | async; as copyAutomationDialogOpen) {
      <dpa-modal
        [modalOpen]="copyAutomationDialogOpen"
        (onModalClose)="closeModal()"
      >
        <dpa-modal-title>
          {{ 'WORKFLOWS.DUPLICATE_' + activeAutomationTypeKey | translate }}
        </dpa-modal-title>
        <div class="modal-body">
          <div class="container">
            <form
              [formGroup]="automationNameForm"
              class="clr-form clr-form-horizontal form"
            >
              <span class="clr-required-mark">{{ 'COMMON_MESSAGES.REQUIRED' | translate }}</span>
              <div class="clr-form-control form-group">
                <label
                  class="clr-control-label clr-required-mark"
                  for="automationName"
                >
                  {{ 'WORKFLOWS.TITLE' | translate }}
                </label>
                <div
                  [class.clr-error]="!automationNameForm.controls.name.valid"
                  class="clr-control-container"
                >
                  <div class="clr-input-wrapper">
                    <input
                      required
                      type="text"
                      class="clr-input automation-name-input"
                      id="automationName"
                      formControlName="name"
                      autofocus
                    />
                  </div>
                  <span class="clr-subtext">
                    {{ 'FORM_VALIDATION.REQUIRED_FIELD' | translate }}
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <button
            (click)="closeModal()"
            class="btn btn-outline"
          >
            {{ 'COMMON_ACTIONS.CANCEL' | translate }}
          </button>
          <button
            [disabled]="automationNameForm.get('name').invalid"
            (click)="confirmCopyAutomation(automationNameForm.value.name)"
            class="btn btn-primary"
          >
            {{ 'COMMON_ACTIONS.DUPLICATE' | translate }}
          </button>
        </div>
      </dpa-modal>
    }

    @if (renameAutomationDialogOpen$ | async; as renameAutomationDialogOpen) {
      <dpa-modal
        [modalOpen]="renameAutomationDialogOpen"
        (onModalClose)="closeModal()"
      >
        <dpa-modal-title>
          {{ 'WORKFLOWS.RENAME_' + activeAutomationTypeKey | translate }}
        </dpa-modal-title>
        <div class="modal-body">
          <div class="container">
            <form
              [formGroup]="automationNameForm"
              class="clr-form clr-form-horizontal form"
            >
              <span class="clr-required-mark">{{ 'COMMON_MESSAGES.REQUIRED' | translate }}</span>
              <div class="clr-form-control form-group">
                <label
                  class="clr-control-label clr-required-mark"
                  for="automationName"
                >
                  {{ 'WORKFLOWS.TITLE' | translate }}
                </label>
                <div
                  [class.clr-error]="!automationNameForm.controls.name.valid"
                  class="clr-control-container"
                >
                  <div class="clr-input-wrapper">
                    <input
                      required
                      type="text"
                      class="clr-input automation-name-input"
                      id="automationName"
                      formControlName="name"
                      autofocus
                    />
                  </div>
                  <span class="clr-subtext">
                    {{ 'FORM_VALIDATION.REQUIRED_FIELD' | translate }}
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <button
            (click)="closeModal()"
            class="btn btn-outline"
          >
            {{ 'COMMON_ACTIONS.CANCEL' | translate }}
          </button>
          <button
            [disabled]="automationNameForm.get('name').invalid"
            (click)="confirmRenameAutomation(automationNameForm.value.name)"
            class="btn btn-primary"
          >
            {{ 'COMMON_ACTIONS.SAVE' | translate }}
          </button>
        </div>
      </dpa-modal>
    }

    @if (deleteAutomationDialogOpen$ | async; as deleteAutomationDialogOpen) {
      <dpa-modal
        [modalOpen]="deleteAutomationDialogOpen"
        (onModalClose)="closeModal()"
      >
        <dpa-modal-title>
          {{ 'WORKFLOWS.DELETE_' + activeAutomationTypeKey | translate: { name: automationName } }}
        </dpa-modal-title>
        <div class="modal-body">
          <div class="container">
            {{ 'WORKFLOWS.DELETE_' + activeAutomationTypeKey + '_WARNING' | translate }}
          </div>
        </div>
        <div class="modal-footer">
          <button
            (click)="closeModal()"
            class="btn btn-outline"
          >
            {{ 'COMMON_ACTIONS.CANCEL' | translate }}
          </button>
          <button
            (click)="confirmDeleteAutomation()"
            class="btn btn-danger"
          >
            {{ 'COMMON_ACTIONS.DELETE' | translate }}
          </button>
        </div>
      </dpa-modal>
    }

    @if (toggleAutomationStatusDialogOpen$ | async; as toggleAutomationStatusDialogOpen) {
      <dpa-modal
        [modalOpen]="toggleAutomationStatusDialogOpen"
        [modalClosable]="false"
        (onModalClose)="closeModal()"
      >
        <dpa-modal-title>
          {{
            (isActive ? 'AUTOMATION_ACTIONS.DISABLE_' + activeAutomationTypeKey : 'AUTOMATION_ACTIONS.ENABLE_' + activeAutomationTypeKey)
              | translate: { name: automationName }
          }}
        </dpa-modal-title>
        <div class="modal-body">
          @if (countAutomationIrrecoverableActions) {
            <dpa-automation-irrecoverable-actions />
          }
          <p>
            <ng-template [ngTemplateOutlet]="enableAutomationText" />
            {{
              (isActive
                ? 'AUTOMATION_ACTIONS.DISABLE_' + activeAutomationTypeKey + '_MESSAGE'
                : 'AUTOMATION_ACTIONS.ENABLE_' + activeAutomationTypeKey + '_MESSAGE'
              ) | translate
            }}
          </p>
        </div>
        <div class="modal-footer">
          <button
            (click)="closeModal()"
            type="button"
            class="btn btn-outline"
          >
            {{ 'COMMON_ACTIONS.CANCEL' | translate }}
          </button>
          <button
            (click)="toggleAutomation()"
            type="button"
            class="btn btn-primary"
          >
            {{ togglePrimaryButtonLabel | translate }}
          </button>
        </div>
      </dpa-modal>
    }

    @if (showSaveAndEnableModal$ | async; as showSaveAndEnableModal) {
      <dpa-modal
        *ngLet="{
          isLoading: isPreviewDataLoading$ | async,
          data: previewData$ | async,
          aboveLimit: arePotentialTargetsAboveLimit$ | async
        } as preview"
        [modalOpen]="showSaveAndEnableModal"
        [modalClosable]="false"
        (onModalClose)="closeModal()"
      >
        <dpa-modal-title>
          @if (evaluatePotentialImpact && preview.isLoading) {
            {{ 'AUTOMATION_ACTIONS.EVALUATING_POTENTIAL_IMPACT' | translate }}
            <span class="spinner spinner-inline ml-x2"></span>
          } @else {
            {{ 'AUTOMATION_ACTIONS.SAVE_' + activeAutomationTypeKey | translate }}
          }
        </dpa-modal-title>
        <div class="modal-body">
          @if (countAutomationIrrecoverableActions) {
            <dpa-automation-irrecoverable-actions />
          }

          <ng-container [ngTemplateOutlet]="oneTimeRunText" />
          @if (evaluatePotentialImpact && !preview.isLoading && preview.aboveLimit) {
            <ng-container
              [ngTemplateOutlet]="excessiveTargetsWarning"
              [ngTemplateOutletContext]="{ potentialTargets: preview.data?.total ?? 0 }"
            />
          }
        </div>
        <div class="modal-footer">
          <button
            [disabled]="isLoadingAutomationRequest$ | async"
            (click)="closeModal()"
            type="button"
            class="btn btn-outline"
          >
            {{ 'COMMON_ACTIONS.CANCEL' | translate }}
          </button>
          <button
            [clrLoading]="isLoadingAutomationRequest$ | async"
            [disabled]="evaluatePotentialImpact && (preview.isLoading || (preview.aboveLimit && !confirmSave))"
            (click)="saveAndEnableAutomation()"
            type="button"
            class="btn btn-primary"
          >
            {{ (oneTimeManualRunControl.value ? 'AUTOMATION_ACTIONS.SAVE_AND_RUN' : 'COMMON_MESSAGES.SAVE_AND_ENABLE') | translate }}
          </button>
        </div>
      </dpa-modal>
    }
    <ng-template #oneTimeRunAlert>
      <clr-alert
        clrAlertType="warning"
        [clrAlertClosable]="false"
        class="mb-x3"
      >
        <clr-alert-item class="alert-item">
          <span class="alert-text">
            {{ 'AUTOMATION_ACTIONS.WILL_IMMEDIATELY_EXECUTE_ON_FILTERED_RESULTS' | translate }}
          </span>
        </clr-alert-item>
      </clr-alert>
    </ng-template>
    <ng-template #oneTimeRunText>
      @if (!isActive && (isOnlySupportsHistoricalData$ | async) === false) {
        <div>
          @if (isAutomaticEvaluationType$ | async) {
            {{ 'AUTOMATION_ACTIONS.SAVE_AND_ENABLE_ONE_TIME_MANUAL_RUN_MESSAGE' | translate }}
          }
          <ng-container [ngTemplateOutlet]="enableAutomationText" />
        </div>
        @if (activeAutomationType === AutomationType.GENERAL) {
          <clr-toggle-container class="mv-x3">
            <clr-toggle-wrapper>
              <input
                clrToggle
                [formControl]="oneTimeManualRunControl"
                id="runAutomationToggle"
                type="checkbox"
              />
              <label for="runAutomationToggle">
                <strong>
                  {{ 'AUTOMATION_ACTIONS.ONE_TIME_MANUAL_RUN' | translate }}
                </strong>
              </label>
            </clr-toggle-wrapper>
            @if (oneTimeManualRunControl.value) {
              <clr-control-helper>
                {{ 'AUTOMATION_ACTIONS.WILL_IMMEDIATELY_EXECUTE_ON_FILTERED_RESULTS' | translate }}
              </clr-control-helper>
            }
          </clr-toggle-container>
        }
      } @else {
        <ng-container [ngTemplateOutlet]="enableAutomationText" />
      }
    </ng-template>
    <ng-template
      #excessiveTargetsWarning
      let-potentialTargets="potentialTargets"
    >
      <div class="card mt-x3 potential-impact-card">
        <div class="card-block">
          <clr-alert
            [clrAlertType]="'warning'"
            [clrAlertClosable]="false"
            [clrAlertLightweight]="true"
          >
            <clr-alert-item class="alert-item">
              <span class="alert-text">
                <strong>{{ 'AUTOMATION_ACTIONS.UNEXPECTED_LARGE_NUMBER_TARGETS' | translate }}</strong>
              </span>
              <div class="alert-actions">
                <clr-dropdown>
                  <button
                    clrDropdownTrigger
                    class="dropdown-toggle"
                  >
                    {{ 'COMMON_ACTIONS.ACTIONS' | translate }}
                    <cds-icon
                      shape="angle"
                      direction="down"
                    />
                  </button>
                  <clr-dropdown-menu
                    *clrIfOpen
                    [clrPosition]="CLARITY_TOOLTIP_POSITION.BOTTOM_RIGHT"
                  >
                    <a
                      clrDropdownItem
                      (click)="openTriggerRules()"
                      class="dropdown-item"
                    >
                      {{ 'AUTOMATION_ACTIONS.EDIT_TRIGGER_RULES' | translate }}
                    </a>
                    <a
                      clrDropdownItem
                      (click)="openPotentialImpactSidepanel()"
                      class="dropdown-item"
                    >
                      {{ 'AUTOMATION_ACTIONS.VIEW_POTENTIAL_IMPACT' | translate }}
                    </a>
                  </clr-dropdown-menu>
                </clr-dropdown>
              </div>
            </clr-alert-item>
          </clr-alert>
          <div class="card-text">
            {{ 'AUTOMATION_ACTIONS.ESTIMATED_POTENTIAL_IMPACT' | translate: { count: potentialTargets | number } }}
            <br />
            <strong>{{ 'AUTOMATION_ACTIONS.HOW_TO_FIX' | translate }}</strong>
            : {{ 'AUTOMATION_ACTIONS.EDIT_TRIGGER_RULES_TO_NARROW_SCOPE' | translate }}
          </div>
        </div>
        <div class="card-footer">
          <clr-checkbox-wrapper>
            <input
              clrCheckbox
              [(ngModel)]="confirmSave"
              type="checkbox"
              id="confirm-execution"
            />
            <label for="confirm-execution">
              {{ 'AUTOMATION_ACTIONS.CONFIRM_EXECUTION' | translate: { count: potentialTargets | number } }}
            </label>
          </clr-checkbox-wrapper>
        </div>
      </div>
    </ng-template>
    <ng-template #enableAutomationText>
      @if (isNewTriggersEnabled$ | async) {
        @if (!isActive && (showSaveAndEnableModal$ | async)) {
          {{ 'WORKFLOWS.SAVE_' + activeAutomationTypeKey + '_MESSAGE' | translate }}
        }
      } @else {
        @if (isInactiveInBatchMode$ | async) {
          {{ 'AUTOMATION_ACTIONS.SAVE_AND_ENABLE_BATCH_MODE_MESSAGE' | translate }}
        }
      }
      @if (isActive) {
        {{ 'AUTOMATION_ACTIONS.CONFIRM_STOP_' + activeAutomationTypeKey | translate }}
      }
    </ng-template>

    @if (runAutomationDialogOpen$ | async; as runAutomationDialogOpen) {
      <dpa-modal
        [modalOpen]="runAutomationDialogOpen"
        [modalClosable]="false"
        (onModalClose)="closeModal()"
      >
        <dpa-modal-title>
          {{ 'AUTOMATION_ACTIONS.RUN_HEADER' | translate: { name: automationName } }}
        </dpa-modal-title>
        <div class="modal-body">
          @if (countAutomationIrrecoverableActions) {
            <dpa-automation-irrecoverable-actions />
          }
          <ng-template [ngTemplateOutlet]="oneTimeRunAlert" />
          <p>
            @if (isBatchModeEnabled$ | async) {
              {{ 'AUTOMATION_ACTIONS.RUN_WARNING_MESSAGE' | translate }}
            }
            {{ 'WORKFLOWS.RUN_MESSAGE' | translate }}
          </p>
        </div>
        <div class="modal-footer">
          <button
            (click)="closeModal()"
            type="button"
            class="btn btn-outline"
          >
            {{ 'COMMON_ACTIONS.CANCEL' | translate }}
          </button>
          <button
            (click)="confirmRunAutomation()"
            type="button"
            class="btn btn-primary"
          >
            {{ 'FILTER_VALUE.YES' | translate }}
          </button>
        </div>
      </dpa-modal>
    }
    @if (showSaveDraftModal$ | async; as showSaveDraftModal) {
      <dpa-modal
        [modalOpen]="showSaveDraftModal"
        [modalClosable]="false"
        (onModalClose)="closeModal()"
        class="save-draft-modal"
      >
        <dpa-modal-title>
          {{ 'WORKFLOWS.ADD_' + activeAutomationTypeKey + '_NAME' | translate }}
        </dpa-modal-title>
        <div class="modal-body">
          <form
            [formGroup]="draftFormGroup"
            class="clr-form clr-form-compat"
          >
            <div class="clr-form-control">
              <dpa-inline-edit
                [autofocus]="true"
                [isInvalid]="requiredWarningVisible('name')"
                [invalidTooltip]="'FORM_VALIDATION.REQUIRED_FIELD' | translate"
                [placeholder]="'WORKFLOWS.NAME_YOUR_' + activeAutomationTypeKey | translate"
                [isRequired]="true"
                formControlName="name"
                size="10rem"
              />
            </div>
            <div class="clr-form-control">
              <textarea
                [placeholder]="'COMMON_MESSAGES.ADD_DESCRIPTION_OPTIONAL' | translate"
                formControlName="description"
              ></textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            (click)="closeModal()"
            type="button"
            class="btn btn-outline"
          >
            {{ 'COMMON_ACTIONS.CANCEL' | translate }}
          </button>
          <button
            [disabled]="draftFormGroup.get('name').invalid"
            (click)="saveDraft()"
            type="button"
            class="btn btn-primary"
          >
            {{ 'COMMON_ACTIONS.SAVE' | translate }}
          </button>
        </div>
      </dpa-modal>
    }

    @if (showEditEnabledAutomationModal$ | async; as showEditEnabledAutomationModal) {
      <dpa-modal
        [modalOpen]="showEditEnabledAutomationModal"
        class="edit-confirmation-modal"
      >
        <dpa-modal-title>
          {{ 'WORKFLOWS.EDITING_AN_ENABLED_' + activeAutomationTypeKey | translate }}
        </dpa-modal-title>
        <div class="modal-body">
          <clr-alert
            clrAlertType="warning"
            [clrAlertClosable]="false"
            class="pb-x8"
          >
            <clr-alert-item class="alert-item">
              <span class="alert-text">
                {{ 'WORKFLOWS.' + activeAutomationTypeKey + '_EDIT_CONFIRMATION' | translate }}
              </span>
            </clr-alert-item>
          </clr-alert>
        </div>
        <div class="modal-footer">
          <button
            (click)="closeModal()"
            type="button"
            class="btn btn-outline"
          >
            {{ 'COMMON_ACTIONS.CANCEL' | translate }}
          </button>
          <button
            (click)="goToEditAutomationPage(automationDialogModel.id, activeAutomationType)"
            type="button"
            class="btn btn-primary"
          >
            {{ 'COMMON_ACTIONS.EDIT' | translate }}
          </button>
        </div>
      </dpa-modal>
    }
  </ng-container>
</ng-container>
