/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GenericObject } from '@dpa/ui-common';
import { Store } from '@ngrx/store';

import { DashboardActions, DeviceProfileActions, SolutionSetupSelectors } from '@ws1c/intelligence-core/store';
import { Column, DataGridColumn, Device, DEVICE_PLATFORM, SolutionSetupCard, SolutionSubmodule } from '@ws1c/intelligence-models';

/**
 * DeviceProfileLinkComponent
 * @export
 * @class DeviceProfileLinkComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-device-profile-link',
  templateUrl: 'device-profile-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceProfileLinkComponent implements OnInit {
  @Input() public columns: Column[];
  @Input() public dataItem: GenericObject = {};
  @Input() public dataGridColumn: DataGridColumn;
  @Input() public deviceLinkMap: Record<string, Column> = {};
  @Input() public useSlideOver?: boolean = false;

  public disableLink: boolean;

  public platformLinkEnablementBySolutions = {
    [DEVICE_PLATFORM.WINDOWS]: SolutionSubmodule.DESKTOP_EXPERIENCE_MANAGEMENT,
    [DEVICE_PLATFORM.APPLE_MACOS]: SolutionSubmodule.DESKTOP_EXPERIENCE_MANAGEMENT,
    [DEVICE_PLATFORM.ANDROID]: SolutionSubmodule.MOBILE_EXPERIENCE_MANAGEMENT,
    [DEVICE_PLATFORM.APPLE]: SolutionSubmodule.MOBILE_EXPERIENCE_MANAGEMENT,
  };
  public solutions: SolutionSetupCard[];

  private destroyRef = inject(DestroyRef);
  private store = inject(Store);

  /**
   * ngOnInit
   * @class DeviceProfileLinkComponent
   */
  public ngOnInit() {
    this.store
      .select(SolutionSetupSelectors.getVisibleSetupCards)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((solutions: SolutionSetupCard[]) => {
        this.solutions = solutions ?? [];
        const platform = this.dataItem[this.deviceLinkMap.platform?.attributeName];
        const solution = this.solutions.find(
          (sol: SolutionSetupCard) => sol.submodule === this.platformLinkEnablementBySolutions[platform],
        );
        this.disableLink = !solution?.isReady || !this.deviceLinkMap.id;
      });
  }

  /**
   * goToDeviceDetailPage
   * @param {GenericObject} device
   * @class DeviceProfileLinkComponent
   */
  public goToDeviceDetailPage(device: GenericObject) {
    if (!device) {
      return;
    }
    const deviceObj = new Device({
      id: device[this.deviceLinkMap?.id?.attributeName]?.toLowerCase(),
      integration: device.integration,
      name: device[this.deviceLinkMap?.name?.attributeName],
      platform: device[this.deviceLinkMap?.platform?.attributeName],
      model: device[this.deviceLinkMap?.model?.attributeName],
    });
    if (this.useSlideOver) {
      this.store.dispatch(DeviceProfileActions.showDeviceProfileSlideOver({ device: deviceObj }));
    } else {
      this.store.dispatch(DashboardActions.goToDeviceDetailPage({ device: deviceObj }));
    }
  }
}
