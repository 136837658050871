/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { GenericObject, unsubscribe } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { CoreAppState, IntegrationMetaSelectors } from '@ws1c/intelligence-core/store';
import { ColumnIndex, FilterRule, QueryBuilder, RuleGroup } from '@ws1c/intelligence-models';

/**
 * FilterEditorComponent
 * @export
 * @class FilterEditorComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-filter-editor',
  templateUrl: 'filter-editor.component.html',
  styleUrls: ['filter-editor.component.scss'],
})
export class FilterEditorComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public initRules: any;
  @Input() public ruleGroup: RuleGroup;
  @Input() public columnsByName: ColumnIndex;
  @Input() public filterColumnsByName?: ColumnIndex;
  @Input() public readOnly?: boolean = false;
  @Input() public showHeader?: boolean = true;
  @Input() public isCrossCategory?: boolean = false;
  @Input() public delimiterSupported?: boolean = false;
  @Input() public showColumnsFromInput?: boolean = false;

  @Output() public rulesChanged = new EventEmitter<QueryBuilder>();
  @Output() public isFilterGroupValid = new EventEmitter<boolean>();

  public viewMode: boolean = false;
  public initUIRules: RuleGroup;
  public queryBuilder: QueryBuilder;
  public allColumnsByName: ColumnIndex;
  public subs: Subscription[] = [];
  public initialRuleGroup: RuleGroup;

  /**
   * Creates an instance of FilterEditorComponent.
   * @param {Store<CoreAppState>} store
   * @memberof FilterEditorComponent
   */
  public constructor(private store: Store<CoreAppState>) {
    this.initialRuleGroup = new RuleGroup([new FilterRule()]);
    this.queryBuilder = new QueryBuilder(this.initialRuleGroup);
  }

  /**
   * ngOnInit
   *
   * @memberof FilterEditorComponent
   */
  public ngOnInit() {
    this.subs.push(
      this.store.select(IntegrationMetaSelectors.getColumnsByName).subscribe((allColumnsByName: ColumnIndex) => {
        this.allColumnsByName = this.columnsByName || allColumnsByName;
        this.queryBuilder = new QueryBuilder(this.initUIRules, this.allColumnsByName);
      }),
    );
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof FilterEditorComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    this.viewMode = this.readOnly;
    if (
      changes.initRules &&
      changes.initRules.currentValue !== null &&
      changes.initRules.previousValue !== changes.initRules.currentValue
    ) {
      this.initUIRules = this.initRules ? this.parseRuleDefinitions(this.initRules) : this.initialRuleGroup;
      this.onRulesChange(this.initUIRules);
    }
    if (changes.ruleGroup) {
      this.initUIRules = this.ruleGroup;
      this.queryBuilder = new QueryBuilder(this.initUIRules, this.allColumnsByName);
    }
    if (changes.columnsByName) {
      this.allColumnsByName = this.columnsByName;
      this.queryBuilder = new QueryBuilder(this.initUIRules, this.allColumnsByName);
    }
  }

  /**
   * ngOnDestroy
   *
   * @memberof FilterEditorComponent
   */
  public ngOnDestroy() {
    unsubscribe(this.subs);
  }

  /**
   * isGroupValid
   * @param {boolean} isValid
   * @memberof FilterEditorComponent
   */
  public isGroupValid(isValid: boolean) {
    this.isFilterGroupValid.emit(isValid);
  }

  /**
   * onRulesChange
   * @param {RuleGroup} $event
   * @memberof FilterEditorComponent
   */
  public onRulesChange($event: RuleGroup) {
    this.queryBuilder = new QueryBuilder($event, this.allColumnsByName);
    this.rulesChanged.emit(this.queryBuilder);
  }

  /**
   * parseRuleDefinitions
   * @param {GenericObject} initRules
   * @returns {RuleGroup}
   * @memberof FilterEditorComponent
   */
  public parseRuleDefinitions(initRules: GenericObject): RuleGroup {
    return QueryBuilder.parseRuleDefinitionTree(initRules);
  }

  /**
   * toggleViewMode
   * @memberof FilterEditorComponent
   */
  public toggleViewMode() {
    this.viewMode = !this.viewMode;
  }
}
